import "../styles/global.css";
import "../i18n";
import "regenerator-runtime/runtime";

import { onDarkBase } from "@biom3/design-tokens";
import { BiomeCombinedProviders } from "@biom3/react";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { Roboto, Roboto_Mono } from "next/font/google";
import localFont from "next/font/local";
import Head from "next/head";
import Script from "next/script";
import type { ComponentType, ReactElement, ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import { appConfig } from "@/constants";
import {
  AnalyticsProvider,
  FeatureFlagProvider,
  FiatPricingProvider,
  ImmutableProvider,
  MoonPayProvider,
  PassportProvider,
  QueryProvider,
  WagmiProvider,
} from "@/context";
import { BrazeProvider } from "@/context/BrazeProvider";
import { CheckoutProvider } from "@/context/CheckoutProvider";
import { PreferencesProvider } from "@/context/PreferencesProvider";

import { AssetImportProvider } from "@/context/AssetImportProvider";
import ErrorComponent from "./_error";

export const breakpoint = {
  small: 430,
  medium: 768,
  large: 905,
  xLarge: 1240,
  xxLarge: 1440,
  xxxLarge: 1920,
};

export const bodyPrimary = Roboto({
  weight: ["400", "500", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

export const bodySecondary = Roboto_Mono({
  weight: ["400", "700"],
  style: ["normal", "italic"],
  subsets: ["latin"],
  display: "swap",
});

export const headingPrimary = localFont({
  display: "swap",
  src: [
    {
      path: "../../node_modules/@biom3/design-tokens/fonts/suisseintl-regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../node_modules/@biom3/design-tokens/fonts/suisseintl-medium.woff2",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../node_modules/@biom3/design-tokens/fonts/suisseintl-bold.woff2",
      weight: "700",
      style: "normal",
    },
  ],
});

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

function App({ Component, pageProps }: AppProps) {
  const { t } = useTranslation();
  // @ts-ignore
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <MoonPayProvider>
        <AnalyticsProvider>
          <ImmutableProvider>
            <PassportProvider>
              <CheckoutProvider>
                <WagmiProvider>
                  <QueryProvider>
                    <PreferencesProvider>
                      <BrazeProvider>
                        <FeatureFlagProvider>
                          <FiatPricingProvider>
                            <BiomeCombinedProviders
                              skipFontLoad
                              theme={{
                                base: {
                                  ...onDarkBase,
                                  font: {
                                    ...onDarkBase.font,
                                    family: {
                                      heading: {
                                        primary:
                                          headingPrimary.style.fontFamily,
                                        secondary:
                                          headingPrimary.style.fontFamily,
                                      },
                                      body: {
                                        primary: bodyPrimary.style.fontFamily,
                                        secondary:
                                          bodySecondary.style.fontFamily,
                                      },
                                    },
                                  },
                                  breakpoint,
                                },
                                globalConfig: {
                                  imageResizeServiceUrl:
                                    appConfig.IMAGE_RESIZER_URL,
                                },
                              }}
                            >
                              <Head>
                                <title>{t("immutable_passport")}</title>
                              </Head>
                              <Script
                                id="newrelic-script"
                                src={`/newrelic-${appConfig.ENVIRONMENT}.js`}
                                onReady={() => {
                                  // @ts-ignore
                                  window.newrelic.setErrorHandler(
                                    (err: { stack?: string | string[] }) =>
                                      !!err.stack?.includes("chrome-extension"),
                                  );
                                }}
                              />
                              <AssetImportProvider>
                                {getLayout(<Component {...pageProps} />)}
                              </AssetImportProvider>
                            </BiomeCombinedProviders>
                          </FiatPricingProvider>
                        </FeatureFlagProvider>
                      </BrazeProvider>
                    </PreferencesProvider>
                  </QueryProvider>
                </WagmiProvider>
              </CheckoutProvider>
            </PassportProvider>
          </ImmutableProvider>
        </AnalyticsProvider>
      </MoonPayProvider>
    </ErrorBoundary>
  );
}

export default withLDProvider({
  clientSideID: appConfig.LAUNCH_DARKLY_CLIENT_ID,
})(App as ComponentType<unknown>);
