import {
  type AllIconKeys,
  Modal as Biom3Modal,
  Box,
  ButtCon,
  Divider,
  Toolbar,
} from "@biom3/react";
import { Children, type ReactNode, isValidElement, useMemo } from "react";

import { ModalActions } from "@/components/modal/ModalActions";
import { ModalContent } from "@/components/modal/ModalContent";
import { ModalContentLayout } from "@/components/modal/ModalContentLayout";
import { appConfig, MODAL_WIDTH } from "@/constants";

type ModalComponents = {
  modalContent: ReactNode;
  modalActions: ReactNode;
};

export function Modal({
  title,
  open,
  children,
  onSubmit,
  rightButtCon,
  leftButtCon,
  onRightButtonClick,
  onLeftButtonClick,
  testId,
  outsideClicksClose = true,
  divider = true,
  width = {},
  closeButtonPadding,
  buttonVariant,
}: {
  title?: string;
  children?: ReactNode;
  onSubmit?: () => void;
  open: boolean;
  rightButtCon?: AllIconKeys;
  leftButtCon?: AllIconKeys;
  testId?: string;
  onRightButtonClick?: () => void;
  onLeftButtonClick?: () => void;
  outsideClicksClose?: boolean;
  divider?: boolean;
  width?: {
    default?: string;
    medium?: string;
    large?: string;
  };
  closeButtonPadding?: string;
  buttonVariant?: "primary" | "secondary" | "tertiary";
}) {
  const { modalContent, modalActions } = useMemo<ModalComponents>(() => {
    let modalContent: ReactNode = null;
    let modalActions: ReactNode = null;
    Children.forEach(children, (child) => {
      if (!isValidElement(child)) return;
      switch (child.type) {
        case ModalContent: {
          modalContent = child;
          break;
        }
        case ModalActions: {
          modalActions = child;
          break;
        }
      }
    });
    return { modalContent, modalActions };
  }, [children]);

  return (
    <Biom3Modal
      visible={open}
      outsideClicksClose={outsideClicksClose}
      onCloseModal={onRightButtonClick}
      testId={testId}
    >
      <Biom3Modal.Content
        sx={{
          d: "flex",
          flexDirection: "column",
          background: "var(--on-dark-base-color-neutrals-500, #1F1F1F)",
          borderRadius: "base.borderRadius.x8",
          boxShadow: "base.shadow.400",
          width: {
            default: "calc(100dvw - 32px)",
            medium: MODAL_WIDTH,
            large: "480px",
            ...width,
          },
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            width: "100%",
            overflow: "auto",
          }}
        >
          {(title || rightButtCon || leftButtCon) && (
            <Toolbar
              testId="modal--toolbar"
              sx={{
                background: "var(--on-dark-base-color-neutrals-500, #1F1F1F)",
                p: closeButtonPadding,
              }}
            >
              {leftButtCon && (
                <Toolbar.LeftButtCon
                  variant="tertiary"
                  icon={leftButtCon}
                  onClick={onLeftButtonClick}
                />
              )}
              <Toolbar.Title
                sx={{
                  wordBreak: "break-word",
                  WebkitLineClamp: 2,
                }}
              >
                {title}
              </Toolbar.Title>
              {rightButtCon && (
                <Toolbar.RightSlot>
                  <ButtCon
                    icon={rightButtCon}
                    onClick={onRightButtonClick}
                    variant={buttonVariant}
                  />
                </Toolbar.RightSlot>
              )}
            </Toolbar>
          )}
          {divider && (
            <Divider
              size="small"
              sx={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                ":after": {
                  bg: "base.color.neutral.600",
                },
              }}
            />
          )}
          <Box
            rc={onSubmit ? <form onSubmit={onSubmit} /> : <div />}
            sx={{ width: "100%", overflowX: "auto" }}
          >
            {modalContent}
            {modalActions}
          </Box>
        </Box>
      </Biom3Modal.Content>
    </Biom3Modal>
  );
}

Modal.Content = ModalContent;
Modal.Actions = ModalActions;
Modal.ContentLayout = ModalContentLayout;
