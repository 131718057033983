import type { User } from "@braze/web-sdk";
import { createContext, useCallback, useContext, useEffect } from "react";

import { appConfig } from "@/constants";
import { usePassportProvider } from "@/context";
import type { SubscriptionStatus } from "@/types";

import { usePrevious } from "@biom3/react";
import { usePreferences } from "./PreferencesProvider";

export const BrazeContext = createContext<{
  unsubscribe: () => void;
  optIn: () => void;
}>({
  unsubscribe: () => {},
  optIn: () => {},
});

const useBraze = () => {
  const ctx = useContext(BrazeContext);
  if (!ctx) {
    throw new Error("useBraze must be used within a BrazeProvider");
  }

  return ctx;
};

// https://www.braze.com/docs/user_guide/message_building_by_channel/email/managing_user_subscriptions/
const BrazeProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  let user: User | undefined;
  const { userInfo } = usePassportProvider();
  const { data, update } = usePreferences();
  const previousData = usePrevious(data);

  useEffect(() => {
    if (previousData !== data) {
      // Only register users in Braze who have opted into marketing consent
      if (userInfo && data.marketing_consent === "opted_in") {
        import("@/utils/braze/exports").then((braze) => {
          const success = braze.initialize(appConfig.BRAZE_API_KEY, {
            baseUrl: appConfig.BRAZE_API_ENDPOINT,
          });
          if (success) {
            braze.changeUser(userInfo.sub);
            user = braze.getUser();
            if (userInfo.email) {
              user?.setEmail(userInfo.email);
            }
            // Only set this on change, the preferences DB isn't the source of truth
            // The user could've unsubscribed externally.
            if (previousData?.marketing_consent !== "opted_in") {
              user?.setPushNotificationSubscriptionType(data.marketing_consent);
              user?.setEmailNotificationSubscriptionType(
                data.marketing_consent,
              );
            }
            braze.openSession();
          }
        });
      }
    }
  }, [data, userInfo, previousData]);

  const updatePreference = useCallback(
    (marketing_consent: SubscriptionStatus) => {
      if (userInfo) {
        update({ ...data, marketing_consent, onboard: false });
      }
    },
    [userInfo, update, data],
  );

  return (
    <BrazeContext.Provider
      value={{
        unsubscribe: () => updatePreference("unsubscribed"),
        optIn: () => updatePreference("opted_in"),
      }}
    >
      {children}
    </BrazeContext.Provider>
  );
};

export { BrazeProvider, useBraze };
