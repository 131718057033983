import { Box, Heading } from "@biom3/react";
import { type ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProtectedLayout, StatefulBox } from "@/components";
import { AppToolbar, AppToolbarLeftSlot } from "@/components/core/AppToolbar";
import { List } from "@/components/games";
import { MainContent } from "@/components/layout/MainContent";
import type { Game } from "@/types";

// See /public/games/README.md for information on image requirements
export const playNowGames = (): Game[] => [
  {
    name: "Guild of Guardians",
    image: "guild-of-guardians-x4",
    link: "https://www.guildofguardians.com",
    context: {
      clientIds: ["rRqnVQ9otuLBHyEy4AqfuLTFnContcai"],
      network: "imx-zkevm-mainnet",
      tokenTroveUri: "https://tokentrove.com/collection/GuildOfGuardians",
      searchQuery: "Guild of Guardians",
    },
  },
  {
    name: "Gods Unchained",
    image: "gods-unchained-x4",
    link: "https://godsunchained.com",
    context: {
      clientIds: [
        "PknPefGrari9Rd2MplPthY2hMYR6Qwc5",
        "qhbADis3iFsFtQEo7LOhTnpXHYK9qrNd",
      ],
      network: "imx-mainnet",
      tokenTroveUri: "https://tokentrove.com/collection/GodsUnchained",
      searchQuery: "Gods Unchained",
    },
  },
  {
    name: "Arkbound",
    image: "arkbound-x4",
    link: "https://store.epicgames.com/en-US/p/gom-c4ffb6",
  },
  {
    name: "Desperado B218",
    image: "desperado-x4",
    link: "https://luxon.games/download/launcher",
  },
  {
    name: "Blast Royale",
    image: "blast_royale",
    link: "https://www.blastroyale.com/",
  },
  {
    name: "Hunters on Chain",
    image: "hunters-on-chain-x4",
    link: "https://hoc-imx.boomland.io",
    context: {
      clientIds: ["u3NIhsjWlWov3glnCQOlx2oW8l7mUCLK"],
      network: "imx-zkevm-mainnet",
      tokenTroveUri: "https://tokentrove.com/collection/HuntersOnChain",
      searchQuery: "Hunters on Chain",
    },
  },
  {
    name: "Cool Cats",
    image: "cool-cats-x4",
    link: "https://play.google.com/store/apps/details?id=com.syncgamesmobile.coolcats&hl=en&gl=us",
  },
  {
    name: "Medieval Empires",
    image: "medieval_empires",
    link: "https://medievalempires.com/",
    context: {
      clientIds: [
        "fGYMknoUI797lgfywR59Tnd5jE9nKdF6",
        "EjgLYCJOIyW2yBNmff21Fpe7HklJwfvK",
      ],
      network: "imx-zkevm-mainnet",
      tokenTroveUri: "https://tokentrove.com/collection/MedievalEmpires",
      searchQuery: "Medieval Empires",
    },
  },
  {
    name: "AEW Figure Fighters",
    image: "aew-figure-fighters-x4",
    link: "https://aewfigurefighters.com/",
    context: {
      clientIds: [
        "cOvmx3f3jw5ZHld3p6S7j4KFqRAEz5R1",
        "DeaHUSnzFrDP4MnjAxCfNf62uefr1c5u",
      ],
      network: "imx-mainnet",
      tokenTroveUri: "https://tokentrove.com/collection/AEWFigureFighters",
      searchQuery: "AEW",
    },
  },
  {
    name: "Storm Warfare",
    image: "storm-warfare-x4",
    link: "https://stormwarfare.com/",
  },
  {
    name: "Rebel Bots: Epic War",
    image: "rebel-bots-x4",
    link: "https://www.epicwar.gg/",
    context: {
      clientIds: [
        "okNnhKbbEPSNNCPedMrxrrdnFKziVtIV",
        "ztHLGWgO5CweMZY8DmpwFzFhQHXQcdeo",
      ],
      network: "imx-zkevm-mainnet",
      tokenTroveUri: "https://tokentrove.com/collection/RebelBotsEpicWar",
      searchQuery: "Epic War",
    },
  },
  {
    name: "Arena of Faith",
    image: "arena-of-faith-x4",
    link: "https://aof.games/",
    context: {
      clientIds: [
        "k9QcWHUJcs7gE72ce9dnF9F47ZaMMa5h",
        "ZdKcDGD7m2inYbMOIHRYaKqm8GigA5u3",
        "54wX9vPz2FKi70ss35IcNyS8WNEegfro",
      ],
      network: "imx-zkevm-mainnet",
      tokenTroveUri: "https://tokentrove.com/collection/ArenaOfFaith",
      searchQuery: "AOF",
    },
  },
  {
    name: "ZOIDS WILD ARENA",
    image: "zoids-wild-arena-x4",
    link: "https://zoidswild.io/",
  },
  {
    name: "Paradise Tycoon",
    image: "paradise-tycoon-x4",
    link: "https://paradisetycoon.com/",
    context: {
      clientIds: [
        "nzHNCVQdGqnQsyvcFbyBN5rDWNe4wTMf",
        "wpzGwUc1iUFX2FYWzJ5yP4kIq01UO1y1",
        "hYhvtL8t5topKceLbAaFkY3JOPjFvz5K",
      ],
      network: "imx-zkevm-mainnet",
      tokenTroveUri: "https://tokentrove.com/collection/ParadiseTycoon",
      searchQuery: "Paradise Tycoon",
    },
  },
  {
    name: "AAO Game",
    image: "aao",
    link: "https://aao.game/",
  },
  {
    name: "Champions TCG",
    image: "champions",
    link: "https://championstcg.com/app/",
  },
  {
    name: "Striker Manager 3",
    image: "striker-manager",
    link: "https://strikermanager.com/",
  },
  {
    name: "Outlaw Troopers",
    image: "outlaw_troopers",
    link: "https://www.outlawtroopers.io/",
  },
  {
    name: "Mystery Society",
    image: "mystery_society",
    link: "https://play.themysterysociety.xyz/",
  },
  {
    name: "Call of the Voyd",
    image: "mystic_games",
    link: "https://mysticgames.dev/",
    context: {
      clientIds: [
        "A2JrgsA8iUlUEz2mUMNhvcrsD79thUjO",
        "JgBFJTsnzZ4zsjdDC7kxvCsR7rKwBLYw",
      ],
      network: "imx-zkevm-mainnet",
      tokenTroveUri: "https://tokentrove.com/collection/CalloftheVoyd",
      searchQuery: "Voyd",
    },
  },
  {
    name: "Awaken",
    image: "awaken",
    link: "https://store.epicgames.com/en-US/p/awaken-257d0f",
  },
  {
    name: "Illuvium",
    image: "illuvium-x4",
    link: "https://store.epicgames.com/en-US/p/illuvium-60064c",
    context: {
      clientIds: [
        "EEG3KsNI2MVQGqe1xZVmFxFRXwXQBYvR",
        "NdyPqBEW8onr81HXB6ylRVDdHBLErEXx",
        "5aXrlXjqJwXNdryIsojurnMLXbavRamJ",
        "F2pXRWn5sFyobVGXfySUDcbOO80Shlhx",
      ],
      network: "imx-mainnet",
      tokenTroveUri: "https://token.trove/illuvium",
      searchQuery: "Illuvium",
    },
  },
  {
    name: "Somnis : Rumble Rush",
    image: "somnis_rumble_rush",
    link: "https://overtake.world/games/obt/somnis",
    context: {
      clientIds: ["Hcandi93ygPn8xeRmgGE58Cw7ecWv7Il"],
      network: "imx-zkevm-mainnet",
      tokenTroveUri: "https://tokentrove.com/collection/SomnisRumbleRush",
      searchQuery: "Somnis",
    },
  },
  {
    name: "Shardbound",
    image: "shardbound-x4",
    link: "https://www.shardbound.com",
  },
  {
    name: "RavenQuest",
    link: "https://ravenquest.com/",
    image: "ravenquest",
  },
  {
    name: "Pool Masters",
    image: "eyeball_pool",
    link: "https://beta.poolmasters.gg/",
  },
  {
    name: "Immortal Rising 2",
    image: "immortal_rising_2",
    link: "https://immortalrising2.com/game",
  },
  {
    name: "Battle Derby",
    link: "https://apps.apple.com/ph/app/battle-derby/id6443468204",
    image: "battle_derby",
  },
  {
    name: "Counter Fire",
    link: "https://www.counterfire.games/",
    image: "counter_fire",
  },
  {
    name: "Baby Shark",
    link: "https://hub.babysharkuniverse.io/",
    image: "baby_shark",
  },
];

export const upcomingGames = (): Game[] => [
  
  {
    name: "MetalCore",
    image: "metalcore-x4",
    link: "https://www.metalcore.gg",
  },
  {
    name: "Infinite Victory",
    image: "infinite-victory-x4",
    link: "https://www.infinitevictory.com",
  },
  {
    name: "WAGMI Defense",
    image: "wagmi-x4",
    link: "https://wagmigame.io",
  },
  {
    name: "Infinity Heroes",
    image: "infinity_heroes",
    link: "https://infinitygames.tech/infinity_heroes/",
  },

  {
    name: "Heroes Chained",
    image: "heroes_chained",
    link: "https://heroeschained.com/",
  },
  {
    name: "Space Nation Online",
    image: "space_nation",
    link: "https://spacenation.online/",
    context: {
      clientIds: ["mioej3B9QT7t9zfN8brxrnXAcQVsEmMF"],
      network: "imx-zkevm-mainnet",
      tokenTroveUri: "https://tokentrove.com/collection/SpaceNation",
      searchQuery: "Space Nation",
    },
  },
  {
    name: "Evermoon",
    image: "evermoon",
    link: "https://www.evermoon.games/home",
  },
  {
    name: "Metastrike",
    image: "metastrike",
    link: "https://metastrike.io/",
  },
  {
    name: "ExoGP",
    image: "exo_gp",
    link: "https://www.playexogp.com/",
  },
  {
    name: "Badmad Robots",
    link: "https://store.steampowered.com/app/2841160/BADMAD_ROBOTS/",
    image: "badmad_robots",
  },
  {
    name: "Bounty Hunters",
    link: "https://bountyhunters.app/",
    image: "bounty_hunters",
  },
  {
    name: "Revomon Nexus",
    link: "https://revomon.io/",
    image: "revomon_nexus",
  },
];

function Games() {
  const { t } = useTranslation();
  const [playNow, setPlayNow] = useState<Game[]>([]);
  const [upcoming, setUpcoming] = useState<Game[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const fetchGames = async () => {
      try {
        setPlayNow(playNowGames());
        setUpcoming(upcomingGames());
        setLoading(false);
      } catch (error) {
        setError(error as Error);
        setLoading(false);
      }
    };
    fetchGames();
  }, [setPlayNow, setUpcoming]);

  return (
    <>
      <AppToolbar>
        <AppToolbarLeftSlot>
          <Heading size="small">{t("screen_title_gameslist")}</Heading>
        </AppToolbarLeftSlot>
      </AppToolbar>

      <StatefulBox isLoading={loading} error={error}>
        <Box sx={{ marginBottom: "48px" }}>
          <Box sx={{ marginBottom: "24px" }}>
            <Heading size={["xSmall", "xSmall", "small"]}>
              {t("games_play_now")}
            </Heading>
          </Box>
          <List games={playNow} />
        </Box>
        <Box>
          <Box sx={{ marginBottom: "24px" }}>
            <Heading size={["xSmall", "xSmall", "small"]}>
              {t("games_upcoming_games")}
            </Heading>
          </Box>
          <List games={upcoming} />
        </Box>
      </StatefulBox>
    </>
  );
}

Games.getLayout = function getLayout(page: ReactElement) {
  return (
    <ProtectedLayout screen="GamesList" userJourney="Games">
      <MainContent>{page}</MainContent>
    </ProtectedLayout>
  );
};

export default Games;
