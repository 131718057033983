import { Card, Grid } from "@biom3/react";
import { useTranslation } from "react-i18next";

import { StatefulBox } from "@/components";
import { useAnalytics } from "@/context";
import type { Game } from "@/types";

export function List({ games }: { games: Game[] }) {
  const { track } = useAnalytics();
  const { t } = useTranslation();
  return (
    <Grid testId="games-list" sx={{ gap: "base.spacing.x6" }}>
      <StatefulBox isEmpty={games.length === 0}>
        {games.map((game) => (
          <Card
            rc={
              <a
                onClick={() => {
                  track({
                    screen: game.name,
                    userJourney: "Games",
                    action: "Pressed",
                    control: "Click",
                    controlType: "Link",
                  });
                }}
                href={game.link}
                target="_blank"
                rel="noreferrer"
              />
            }
            testId={`game-${game.name}`}
            key={game.name}
            sx={{ background: "base.color.brand.2", height: "100%" }}
          >
            <Card.Title testId={`game-title-${game.name}`}>
              {game.name}
            </Card.Title>
            {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
            <Card.Description>{t(game.description!)}</Card.Description>
            <Card.AssetImage
              imageUrl={`${window.location.origin}/games/${game.image}.webp`}
              aspectRatio="1:1"
              // @TODO: setting this to what the default used to be,
              // (eg while this bug existed: https://github.com/immutable/biom3/pull/341)
              // but should be probably be set to the actual image size
              relativeImageSizeInLayout="1024px"
            />
          </Card>
        ))}
      </StatefulBox>
    </Grid>
  );
}
