import {
  type AllIconKeys,
  type BadgeProps,
  Box,
  Divider,
  Icon,
  LoadingOverlay,
  MenuItem,
  Modal,
} from "@biom3/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { type Ref, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { SIDEBAR_WIDTH } from "@/constants";
import { useAnalytics, useFeatureFlag, usePassportProvider } from "@/context";
import { useAssetImport } from "@/context/AssetImportProvider";
import { useCheckout } from "@/context/CheckoutProvider";
import { usePreferences } from "@/context/PreferencesProvider";
import {
  useCoinBalancesZkEVMQuery,
  useCollectionsQuery,
  useFetchWallets,
} from "@/hooks";
import { useGetWalletGems } from "@/hooks/useGetWalletGems";
import { type HideActivationBadges, WalletTypes } from "@/types";
import { ActivationVariants } from "../emptystates/useActivationExperiment";
import { LegalLinks } from "./LegalLinks";

type MenuItemType = {
  key: string;
  label: string;
  path: string;
  icon: string;
  badge?: {
    badgeContent?: (count: number) => string;
    variant: BadgeProps["variant"];
    isAnimated?: boolean;
  };
  divider?: boolean;
  linkProps?: {
    isExternal?: boolean;
    isTrusted?: boolean;
  };
};

const menuItems: MenuItemType[] = [
  {
    key: "balances",
    label: "balance",
    path: "/balance",
    icon: "Wallet",
    badge: { variant: "guidance", isAnimated: true },
  },
  {
    key: "inventory",
    label: "inventory",
    path: "/inventory",
    icon: "TreasureChest",
    badge: { variant: "guidance", isAnimated: true },
  },
  {
    key: "external_wallets",
    label: "external_wallets",
    path: "/external-wallets",
    icon: "WalletConnect",
    badge: { variant: "guidance", isAnimated: true },
    divider: true,
  },
  {
    key: "games",
    label: "games",
    path: "/games",
    icon: "ESports",
  },
  {
    key: "daily_gems",
    label: "daily_gems",
    path: "https://imx.community/gems",
    icon: "ImxRewards",
    badge: {
      variant: "emphasis",
      badgeContent: (count: number) => `${count} • Claimed`,
    },
    linkProps: {
      isExternal: true,
      isTrusted: true,
    },
  },
  {
    key: "logout",
    label: "logout",
    path: "/logout",
    icon: "Logout",
  },
];

export function SideMenu({
  domRef,
  onItemClick,
  fullWidth,
}: {
  domRef: Ref<HTMLDivElement>;
  onItemClick: () => void;
  fullWidth?: boolean;
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const { activeImport } = useAssetImport();
  const [loggingOut, setLoggingOut] = useState(false);
  const sidebarWidth = fullWidth ? "100%" : SIDEBAR_WIDTH;
  const { walletAddress, logout } = usePassportProvider();
  const { data } = useGetWalletGems(walletAddress);
  const { checkoutClient, checkoutNetwork } = useCheckout();
  const { data: preferencesData, update: updatePreferences } = usePreferences();
  const { data: coinBalancesData } = useCoinBalancesZkEVMQuery(
    walletAddress,
    checkoutClient,
    checkoutNetwork,
  );
  const { data: inventoryData, refetch: refetchInventoryData } =
    useCollectionsQuery(activeImport?.importAddress, true);
  const { data: walletsData } = useFetchWallets(true);
  const [shouldRefetchInventory, setShouldRefetchInventory] = useState(false);
  const { track } = useAnalytics();
  const { flags } = useFeatureFlag();
  const activation0Experiment = useMemo(
    () => flags?.activation0Experiment ?? ActivationVariants.control,
    [flags],
  );

  // Update preferences based on coin balances
  useEffect(() => {
    if (
      coinBalancesData &&
      coinBalancesData.length > 0 &&
      preferencesData &&
      !preferencesData.hide_activation_badges.balances
    ) {
      track({
        screen: "EmptyState",
        userJourney: "Balances",
        control: "HideActivationBadge",
        controlType: "Effect",
      });
      updatePreferences({
        hide_activation_badges: {
          ...preferencesData.hide_activation_badges,
          balances: true,
        },
      });
    }
  }, [coinBalancesData, preferencesData, updatePreferences]);

  // Check for inventory data and update preferences
  useEffect(() => {
    if (
      preferencesData &&
      !preferencesData.hide_activation_badges.inventory &&
      inventoryData &&
      inventoryData.collections.length > 0
    ) {
      track({
        screen: "EmptyState",
        userJourney: "Collections",
        control: "HideActivationBadge",
        controlType: "Effect",
      });
      updatePreferences({
        hide_activation_badges: {
          ...preferencesData.hide_activation_badges,
          inventory: true,
          external_wallets: true,
        },
      });
    }
  }, [inventoryData, preferencesData, updatePreferences]);

  // Check for external wallets and set flag for inventory refetch
  useEffect(() => {
    if (walletsData && preferencesData) {
      const externalLinkedWallets = walletsData.filter(
        (wallet) => wallet.type !== WalletTypes.PASSPORT,
      );
      if (
        externalLinkedWallets.length > 0 &&
        !preferencesData.hide_activation_badges.external_wallets
      ) {
        track({
          screen: "EmptyState",
          userJourney: "Wallets",
          control: "HideActivationBadge",
          controlType: "Effect",
        });
        updatePreferences({
          hide_activation_badges: {
            ...preferencesData.hide_activation_badges,
            external_wallets: true,
          },
        });
        setShouldRefetchInventory(true);
      }
    }
  }, [walletsData, preferencesData, updatePreferences]);

  // Refetch inventory data when external wallets are linked and update inventory badge status
  useEffect(() => {
    const updateInventoryBadge = async () => {
      if (shouldRefetchInventory && preferencesData) {
        await refetchInventoryData();
        setShouldRefetchInventory(false);
      }
    };
    updateInventoryBadge();
  }, [
    shouldRefetchInventory,
    refetchInventoryData,
    inventoryData,
    preferencesData,
    updatePreferences,
  ]);

  const onItem = () => {
    onItemClick();
  };

  const onGemsTab = () => {
    track({
      userJourney: "Gems",
      screen: "PassportDashboard",
      control: "SideMenu",
      controlType: "Link",
      action: "Pressed",
    });
    onItemClick();
  };

  const onLogout = () => {
    onItemClick();
    setLoggingOut(true);
    logout();
  };

  const shouldDisplayBadge = useCallback(
    (key: string) => {
      if (
        preferencesData &&
        activation0Experiment !== ActivationVariants.control
      ) {
        const { hide_activation_badges: hideActivationBadges } =
          preferencesData;
        return hideActivationBadges[key as keyof HideActivationBadges] !== true;
      }
    },
    [preferencesData, activation0Experiment],
  );

  return (
    <Box
      domRef={domRef}
      sx={{
        w: "100%",
        flexShrink: 0,
        maxw: sidebarWidth,
        h: "100%",
        top: [null, null, "0px"],
        pos: "sticky",
        d: "flex",
        flexDirection: "column",
        transitionProperty: "transform",
        transitionDuration: "base.animation.fast.cssDuration",
        transitionTimingFunction: "base.animation.fast.cssEasing",
        overflow: "auto",
        boxShadow: "base.shadow.200",
        borderTopRightRadius: "base.borderRadius.x8",
        zIndex: 2,
        background: "base.color.translucent.standard.150",
      }}
      rc={<aside />}
    >
      <Box
        sx={{
          minh: "100%",
          borderTopRightRadius: "base.borderRadius.x8",
          px: "base.spacing.x4",
          pt: "base.spacing.x4",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {menuItems.map((item) => (
          <Box key={item.key}>
            <MenuItem
              testId={`menu-item__${item.label}`}
              size="xSmall"
              sx={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                "&.selected": {
                  backgroundColor:
                    "var(--on-dark-base-color-translucent-emphasis-500, rgba(243, 243, 243, 0.20))",
                },
                paddingY: "1px",
                my: "base.spacing.x2",
              }}
              selected={router.pathname === item.path}
              {...(item.path === "/logout"
                ? { onClick: onLogout }
                : {
                    rc: (
                      <Link
                        href={`${item.path}`}
                        target={
                          item.linkProps?.isExternal ? "_blank" : undefined
                        }
                        referrerPolicy={
                          item.linkProps?.isTrusted
                            ? "origin"
                            : item.linkProps?.isExternal
                              ? "no-referrer"
                              : undefined
                        }
                        rel={
                          item.linkProps?.isTrusted
                            ? "external"
                            : item.linkProps?.isExternal
                              ? "noreferrer"
                              : undefined
                        }
                      />
                    ),
                    onClick: item.key === "daily_gems" ? onGemsTab : onItem,
                  })}
            >
              <MenuItem.Label sx={{ p: "0px" }}>{t(item.label)}</MenuItem.Label>
              <MenuItem.Icon
                testId={`menu-item__${item.icon}`}
                icon={item.icon as AllIconKeys}
                variant="bold"
              />
              {item.badge ? (
                item.key === "daily_gems" ? (
                  data ? (
                    <MenuItem.Badge
                      testId={"menu-item__badge-gems"}
                      badgeContent={item?.badge?.badgeContent?.(data.gems)}
                      variant={item.badge.variant}
                      isAnimated={item.badge.isAnimated}
                    />
                  ) : (
                    <MenuItem.Badge
                      testId={"menu-item__badge-dot"}
                      showZero
                      variant={item.badge.variant}
                      isAnimated={item.badge.isAnimated}
                    />
                  )
                ) : (
                  shouldDisplayBadge(item.key) && (
                    <MenuItem.Badge
                      testId={"menu-item__badge-dot"}
                      showZero
                      variant={item.badge.variant}
                      isAnimated={item.badge.isAnimated}
                    />
                  )
                )
              ) : null}
              {item.linkProps?.isExternal ? (
                <Icon icon="JumpTo" sx={{ width: "16px" }} />
              ) : null}
            </MenuItem>
            {item.divider && <Divider size="xSmall" />}
            <Modal visible={loggingOut}>
              <Modal.Content>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    p: "base.spacing.x12",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <LoadingOverlay.Content sx={{ alignItems: "center" }}>
                    <LoadingOverlay.Content.LoopingText
                      text={[t("logging_out")]}
                    />
                  </LoadingOverlay.Content>
                </Box>
              </Modal.Content>
            </Modal>
          </Box>
        ))}
        <Box sx={{ flexGrow: 1 }} />
        <LegalLinks
          containerSx={{
            px: "base.spacing.x2",
            py: "base.spacing.x10",
          }}
        />
      </Box>
    </Box>
  );
}
