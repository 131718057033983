import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const english = {
  translation: {
    add_coin: "Add {{symbol}}",
    add_coin_menu: "Add funds",
    add_coin_title: "Let's add funds to Immutable zkEVM",
    add_coin_imx_title: "Let's add funds to Immutable X",
    imx_only: "Immutable X only",
    add_to_your_collection: "Add to your collection",
    add_zkevm_balances: "Add zkEVM balances",
    approve_connection_request:
      "Approve the connection request to link your wallet",
    balance: "Balance",
    buy: "Buy",
    buy_caption: "With card, Google Pay or Apple Pay.",
    buy_coin: "Buy {{symbol}}",
    application_tagline: "Your secure gaming wallet & profile",
    asset_transfer_confirming: "Confirming in {{connectorName}}",
    asset_transfer_failure_account_mismatch_description:
      "Make sure your MetaMask browser extension is connected to account you’re importing from (ending ...{{walletEnding}}), then try again.",
    asset_transfer_failure_account_mismatch_title:
      "Are you connected to the right account in your browser?",
    asset_transfer_failure_chain_switch_description:
      "Make sure the <networkLink>Immutable zkEVM network</networkLink> <linkIcon></linkIcon> is added and selected in your wallet browser extension, then try again.",
    asset_transfer_failure_chain_switch_title:
      "Add Immutable zkEVM network to your wallet extension",
    asset_transfer_failure_description:
      "Please try again in a couple of minutes.<br/><br/>Your items are still in your original wallet, and we’ve logged this issue to investigate.",
    asset_transfer_modal_title: "Review for import",
    asset_transfer_progress_description:
      "That should be in your inventory in the next few minutes. View <transactionLink>transaction</transactionLink>",
    asset_transfer_progress_title: "They’re on their way!",
    asset_transfer_success_description:
      "You can import more collections now, or leave them as read only. View <transactionLink>transaction</transactionLink>",
    asset_transfer_success_title: "That's now in your Passport!",
    asset_transfer_view_transaction: "View transaction on block explorer",
    asset_transfer_wallet_loading: "Loading wallets",
    bridge: "Bridge",
    bridge_caption: "From another network",
    bridge_modal_title: "Are you bridging funds in or out?",
    bridging_in: "In from another network",
    bridging_out: "Out to Ethereum (Layer 1)",
    bridge_in_title: "How would you like to bridge?",
    bridge_in_subtitle: "Choose from one of our providers below",
    bring_your_own: "Bring your own",
    browse_marketplaces: "Browse Marketplaces",
    cancel: "Cancel",
    cex_deposit: "Deposit from a CEX",
    cex_deposit_caption: "Via Layerswap",
    change_language: "Change Language",
    check_metamask_modal_import_body:
      "Follow the prompts in the Metamask pop-up to connect to Passport. Then choose which collectibles to import.",
    check_metamask_modal_link_body:
      "Follow the prompts in the Metamask pop-up to link your wallet as view only. ",
    check_metamask_modal_title: "Check for the pop-up from MetaMask",
    choose_more_to_import: "Choose more to import",
    choose_your_wallet: "Choose your wallet",
    close: "Close",
    collection_count_one: "{{count}} collection",
    collection_count_other: "{{count}} collections",
    collection_empty_heading: "Nothing here yet!",
    confirm_import: "Confirm in {{source.name}}",
    confirm_item_heading: "Import this item to Passport?",
    confirm_many: "Select more from this collection",
    confirm_single_item: "Just this item",
    copied: "Copied",
    creating_wallet: "Creating wallet, this could take several seconds.",
    description: "Description",
    deselect_all: "Deselect all",
    destination: "To",
    details: "Details",
    dev_uses_testnet_warning:
      "Balances on this page are retrieved from testnet, not devnet.",
    ethereum_only: "Ethereum only",
    error_heading: "Something went wrong {{statusCode}}",
    external_wallets_allows: "View and play only",
    external_wallets_body:
      "If you have Immutable X or Immutable zkEVM game items sitting in another wallet and want to use them in game, all you have to do is link your EOA wallet (or a hardware wallet). <howItWorks>How it works</howItWorks>",
    external_wallets_external: "Your external wallet",
    external_wallets_heading: "Got collectibles in another wallet?",
    external_wallets_import_con_one:
      "There's gas on Immutable zkEVM (just for the import from MetaMask) - for games like Guild of Guardians, MetalCore and Shardbound",
    external_wallets_import_cons_heading: "IMX needed for some imports",
    external_wallets_import_pro_one:
      "Play, use and craft, or trade items in games and marketplaces",
    external_wallets_import_pro_three:
      "Gas free on Immutable X - for games like Gods Unchained and Illuvium",
    external_wallets_import_pro_two:
      "Import whole collections, multiple or single items",
    external_wallets_import_pros_heading: "Play, craft, trade",
    external_wallets_no_trading:
      "No crafting or trading of collectibles from the external wallet in game",
    external_wallets_read_only:
      "Coins and collectibles will be view only in game",
    external_wallets_use_in_game:
      "Play with these collectibles in Passport enabled games",
    external_wallets: "External wallets",
    daily_gems: "Daily Gems",
    from_collection: "From {{collectionName}}",
    games_play_now: "Play now",
    games_upcoming_games: "Upcoming games",
    games: "Games",
    im_done: "I'm done",
    immutable_passport: "Immutable Passport",
    immutable_x: "Immutable X",
    immutable_zkevm: "Immutable zkEVM",
    immutable: "Immutable",
    import_items_one: "Import {{count}} item",
    import_items_other: "Import {{count}} items",
    import_items_to_passport: "Import items to Passport",
    import_or_link: "Import or link?",
    import: "Import",
    import_not_supported: "Importing not available yet for this item type",
    import_option_modal_title:
      "Now, let’s import your collectables to use them in game",
    inventory: "Inventory",
    inventory_action_buy: "Buy",
    inventory_action_sell: "Sell",
    inventory_action_import: "Import",
    inventory_action_transfer: "Transfer",
    item_count_one: "{{count}} item",
    item_count_other: "{{count}} items",
    item_owned_by_linked_wallet_message: "This item is in your linked {{name}}",
    item_owned_by_linked_wallet_message_on_imx:
      "To play, craft, and trade this NFT within the game, head over to that wallet and transfer it to your Passport.",
    language: "English",
    layerswap: "Bridge from other chains",
    layerswap_caption: "Faster bridge via Layerswap",
    link_a_wallet_as_view_only_instead: "Link items as view only instead",
    link_a_wallet: "Link a wallet",
    link_wallet_modal_body:
      "To play, craft and trade your in-game items on Passport, import them below. <learnMore>Learn more</learnMore>",
    link_wallet_modal_heading: "Import your collection",
    link_wallet_success_title: "Wallet linked",
    linked_wallets: "Linked Wallets",
    loading_moonpay: "Loading Moonpay",
    login_active: "Logging in",
    login_check_popup: "Can’t see the sign-in pop-up?",
    login_check_tab: "Check your next tab",
    login_confirmed: "You are logged in!",
    login_welcome: "Welcome",
    logout: "Log Out",
    logging_out: "Logging Out...",
    marketplace_summary:
      "'View, buy, and sell collectibles at our partner marketplaces.'",
    marketplace_buy: "Buy",
    marketplace_sell: "Sell",
    marketplace_buy_sell: "Buy or sell at our marketplaces",
    move_coin: "Bridge {{symbol}}",
    multiple_networks: "Multiple networks",
    multiple_coins: "Multiple coins",
    network: "Network",
    not_found_body: "We could not find requested resource",
    not_found_heading: "File not found",
    not_found_link: "Return home",
    number_of_items: "Number of items",
    ok_got_it: "Ok, got it",
    ok: "OK",
    only_in_select_region: "Only in select regions",
    passport_wallet_address: "Your Passport Wallet Address",
    passport: "Passport",
    ready_for_import: "Select a collection to import from",
    remove: "Remove",
    reset_preferences: "Reset Preferences",
    retry: "Try Again",
    retry_after_swtich_network: "I’ve switched, try again",
    sell: "Sell",
    screen_title_coinbalancelist: "Balance",
    screen_title_collectionlist: "Inventory",
    screen_title_externalwallets: "External Wallets",
    screen_title_gameslist: "Games",
    select_all: "Select all",
    select_to_import: "Select to import",
    sell_coin_modal_cta: "Go to TokenTrove",
    sell_coin_modal_desc: "You can’t sell your {{symbol}} here just yet.",
    sell_coin_modal_point_1: "Head to TokenTrove",
    sell_coin_modal_point_2: "Connect your Passport",
    sell_coin_modal_point_3: "Choose ‘Buy/sell Crypto with MoonPay’",
    sell_coin_modal_title: "How to sell {{symbol}} in TokenTrove",
    sell_coin: "Sell {{symbol}} via TokenTrove",
    show_address: "I understand, show address",
    showing_items_count: "Showing {{count}} items",
    showing_items_selection_count: "{{selected}} of {{count}} items",
    show_more: "Show more",
    show_more_items: "Show more items",
    sign_in: "Sign In",
    signing_in_popup: "Can't see the sign-in pop-up?",
    signing_in_tab: "Check your next tab",
    signing_in: "Signing in",
    source: "From",
    starkex_balance: "Balances on Immutable X",
    start_collection: "Start your collection",
    stateful_empty_body: "Nothing here yet!",
    stateful_error_body: "Please try again in a few minutes",
    stateful_error_heading: "We hit a glitch - we’re onto it",
    statistics: "Stats",
    swap_action: "Swap",
    swap: "Swap",
    swap_caption: "From another coin you own",
    swap_caption_not_available: "Not available in your region",
    swap_coin: "Swap to another coin",
    token_history: "Token History",
    transfer: "Transfer",
    transfer_coin: "Transfer {{symbol}}",
    transfer_caption: "Immutable zkEVM only",
    updated_at: "Updated At",
    view_on: "View on {{site}}",
    wallet: "Wallet",
    weve_hit_a_glitch: "We've hit a glitch",
    sending_assets_to_passport: "Sending assets to Passport?",
    dont_transfer_on_ethereum_mainnet:
      "Don't transfer on Ethereum Mainnet (L1) or any other EVM chain.",
    only_transfer_on_immutable_x_or_immutable_zkevm:
      "<emphasised>Only transfer on Immutable X or zkEVM</emphasised>. Do this here in <wallet>Wallet</wallet> or via <tokenTrove>TokenTrove</tokenTrove>",
    linking_wallet: "Linking wallet",
    link_a_wallet_name: "Link your wallet",
    sending_funds_to_passport: "Sending funds to your Passport",
    only_add_funds_on_immutable_x_or_immutable_zkevm:
      "Only transfer on Immutable X or zkEVM.",
    you_can_add_funds_by:
      "You can do this here in <wallet>Wallet</wallet> (add funds) or by connecting your wallet in <tokenTrove>TokenTrove</tokenTrove>",
    passport_l2_wallet_address: "Passport L2 wallet address",
    sort_newest_first: "Newest first",
    sort_oldest_first: "Oldest first",
    link_wallet_already_linked_wallet:
      "Looks like you already linked this wallet!",
    link_wallet_something_went_wrong: "Oops, something went wrong",
    link_wallet_could_not_be_linked:
      "Your wallet couldn’t be linked this time.",
    link_wallet_link_additional_wallets:
      "To link additional wallets, switch to another account in your MetaMask extension or app first.",
    link_wallet_ensure_metamask_is_connected:
      "Make sure your MetaMask is connected (in your browser extension or mobile app), and try again.",
    link_wallet_error_ok_button: "Ok, got it",
    marketing_consent_request:
      "Don't miss out on rewards, exclusive content, promos and more",
    marketing_consent_body:
      "We'll email you about Immutable's products and services. You can unsubscribe at any time.",
    marketing_consent_confirm: "Yes please",
    marketing_consent_reject: "Not now",
    wallet_connectors_modal_title: "Which wallet do you want to link?",
    wallet_import_modal_title: "Which wallet do you want to import items from?",
    wallet_import_modal_caption:
      "Only zkEVM collectibles can be imported into your Passport wallet.",
    waiting_for_wallet_link: "Waiting for you to link your wallet",
    with_layerswap: "With Layerswap",
    with_axelar: "With Axelar",
    terms_of_service: "Terms of Service",
    no_smart_contracts:
      "If you’re using a smart contract wallet (not EOA) it’s not supported.",
    link_wallet_failed: "We failed to link the wallet.",
    link_wallet_failed_heading: "Could not link your wallet",
    link_wallet_failed_unverified:
      "We tried to call the wallet and we could not verify the signature.",
    link_wallet_failed_connect_failed:
      "We tried to call the wallet and it could not connect.",
    link_wallet_failed_network_failed_heading: "Switch to the Ethereum network",
    link_wallet_failed_network_failed:
      "Your wallet is currently connected to another network. Please switch networks to link your wallet.",
    link_wallet_failed_already_linked:
      "If your wallet has multiple accounts, ensure you’re connecting to the right account.",
    link_wallet_failed_already_linked_heading:
      "This wallet has already been linked",
    unlink_wallet_modal_title: "Unlink collectibles from games?",
    unlink_wallet_modal_caption:
      "You won’t be able to use collectibles in {{address}} when you use Passport in game.",
    unlink_wallet_confirm: "Yes, unlink wallet",
    unlink_wallet_failed_modal_title: "Something went wrong",
    unlink_wallet_failed_modal_caption:
      "We couldn't unlink your wallet. Please try again.",
    no_leave_items: "No, leave items",
    link_wallet_failed_max_wallets_heading:
      "You’ve reached the maximum number of linked wallets",
    best_for_cex: "Best for CEX and Immutable X",
    recommended: "Recommended",
    with_squid: "With Squid",
    nickname: "Nickname",
    unlink: "Unlink",
    balances_empty_title_variation_a: "So many games,\nso little funds",
    balances_empty_title_variation_a_with_selected_game:
      "Fund your {{gameName}} quest",
    balances_empty_caption_variation_a:
      "You never know when the next gaming gold rush will hit. Be ready",
    balances_empty_caption_variation_a_with_selected_game:
      "Fund your adventures and collect rare items from {{gameName}}, Illuvium, and more.",
    balances_empty_primary_button_a: "Add funds",
    balances_empty_secondary_button_a: "Learn more",
    balances_empty_title_variation_b: "No funds when the sale comes?",
    balances_empty_caption_variation_b:
      "Don't let empty pockets stand between you and your dream games items",
    balances_empty_primary_button_b: "Add funds",
    balances_empty_secondary_button_b: "Learn more",
    learn_more: "Learn more",
    inventory_empty_title_variation_a:
      "A single place for your all game collectables",
    inventory_empty_title_variation_a_with_selected_game:
      "Your {{gameName}} collection starts here",
    inventory_empty_caption_variation_a:
      "Explore true ownership of your favourite game items",
    inventory_empty_caption_variation_a_with_selected_game:
      "Own your gaming world. Explore {{gameName}}, Illuvium, and more in one place.",
    inventory_empty_primary_button_a: "Start collecting",
    inventory_empty_secondary_button_a: "Import or link your collections",
    inventory_empty_title_variation_b: "Nothing here!\nLet’s change that",
    inventory_empty_caption_variation_b:
      "Explore true ownership of your favourite game items",
    inventory_empty_primary_button_b: "Browse collections",
    inventory_empty_secondary_button_b: "Bring them from another wallet",
    wallets_empty_title_variation_a: "Got collectables?\nUse them in game",
    wallets_empty_title_variation_a_with_selected_game:
      "Got {{gameName}} collectables in another wallet?",
    wallets_empty_caption_variation_a:
      "Link a wallet (EOA or hardware) to use your collectables in game",
    wallets_empty_caption_variation_a_with_selected_game:
      "Link a wallet (EOA or hardware) to use your collectables in game",
    wallets_empty_primary_button_a: "Select a wallet to get started",
    wallets_empty_secondary_button_a: "Learn more",
    wallets_empty_title_variation_b:
      "Don’t leave your NFTs locked in another wallet",
    wallets_empty_caption_variation_b:
      "Use your collectables in game, just link a wallet (EOA or hardware) to get started",
    wallets_empty_primary_button_b: "Link a wallet",
    wallets_empty_secondary_button_b: "Learn more",
    creating_wallet_title: "Creating your wallet...",
    creating_wallet_caption: "Hang tight",
    link_wallet_success_empty_title:
      "No zkEVM collectibles found in this wallet",
    link_wallet_success_empty_description:
      "Once you add Immutable X and Immutable zkEVM game collectibles to your wallet, they will show up on your inventory as view-only. Only zkEVM collectibles can be imported into your Passport wallet.",
    link_wallet_success_empty_link_another_cta: "Link another wallet",
  },
};

const chinese = {
  translation: {
    ...english.translation,
    close: "关闭",
    retry: "重试",
    retry_after_swtich_network: "I’ve switched, try again",
    ok: "OK",
    inventory_action_buy: "Buy",
    inventory_action_sell: "Sell",
    inventory_action_import: "Import",
    inventory_action_transfer: "Transfer",
    marketplace_buy: "Buy",
    marketplace_sell: "Sell",
    marketplace_buy_sell: "Buy or sell at our marketplaces",
    cex_deposit: "Deposit from a CEX",
    cex_deposit_caption: "Via Layerswap",
    layerswap: "Bridge from other chains",
    layerswap_caption: "Faster bridge via Layerswap",
    add_coin: "添加{{symbol}}",
    add_to_your_collection: "添加到您的收藏",
    add_zkevm_balances: "添加 zkEVM 余额",
    application_tagline: "您的安全游戏钱包和个人资料",
    asset_transfer_confirming_in_metamask: "在 MetaMask 中确认",
    asset_transfer_failure_account_mismatch_description:
      "确保您的 MetaMask 浏览器扩展程序连接到您要导入的帐户（以 ...{{walletEnding}} 结尾），然后重试。",
    asset_transfer_failure_account_mismatch_title:
      "您是否在浏览器中连接到正确的帐户？",
    asset_transfer_failure_chain_switch_description:
      "确保在您的钱包浏览器扩展程序中添加并选择了 <networkLink>Immutable zkEVM 网络</networkLink> <linkIcon></linkIcon>，然后重试。",
    asset_transfer_failure_chain_switch_title:
      "将 Immutable zkEVM 网络添加到您的钱包扩展中",
    asset_transfer_failure_description:
      "请在几分钟后再试。<br/><br/>您的物品仍在原始钱包中，我们已记录此问题以进行调查。",
    asset_transfer_modal_title: "导入审核",
    asset_transfer_progress_description:
      "这些物品将在接下来的几分钟内出现在您的库存中。查看 <transactionLink>交易</transactionLink>",
    asset_transfer_progress_title: "它们正在路上！",
    asset_transfer_success_description:
      "您现在可以导入更多收藏，或将其保留为只读。查看 <transactionLink>交易</transactionLink>",
    asset_transfer_success_title: "现在在您的 Passport 中！",
    asset_transfer_view_transaction: "在区块浏览器上查看交易",
    asset_transfer_wallet_loading: "正在加载钱包",
    balance: "Balance",
    bring_your_own: "自带",
    browse_marketplaces: "浏览市场",
    buy_coin: "买 {{symbol}}",
    cancel: "取消",
    change_language: "更改语言",
    check_metamask_modal_import_body:
      "按照 MetaMask 弹出窗口中的提示连接到 Passport。然后选择要导入的收藏品。",
    check_metamask_modal_link_body:
      "按照 MetaMask 弹出窗口中的提示将您的钱包链接为只读。",
    check_metamask_modal_title: "检查 MetaMask 弹出窗口",
    choose_more_to_import: "选择更多以导入",
    choose_your_wallet: "选择您的钱包",
    collection_count_one: "{{count}}个收藏",
    collection_count_other: "{{count}}个收藏",
    collection_empty_heading: "目前没有任何内容！",
    confirm_import: "在{{source.name}}中确认",
    confirm_item_heading: "将此物品导入 Passport？",
    confirm_many: "从此收藏中选择更多",
    confirm_single_item: "只有此物品",
    copied: "Copied",
    creating_wallet: "正在创建钱包，这可能需要几秒钟。",
    description: "描述",
    deselect_all: "取消全选",
    destination: "到",
    details: "详情",
    error_heading: "发生错误 {{statusCode}}",
    external_wallets_allows: "仅查看和游玩",
    external_wallets_body:
      "将您的 Guild of Guardians、Gods Unchained、MetalCore、Shardbound 等游戏物品带入。只需连接您的 MetaMask（仅限桌面）。 <howItWorks>它是如何工作的</howItWorks>",
    external_wallets_external: "您的外部钱包",
    external_wallets_heading: "在另一个钱包中有可玩的收藏品？",
    external_wallets_import_con_one:
      "在 Immutable zkEVM 上有燃气（仅用于从 MetaMask 导入）- 适用于 Guild of Guardians、MetalCore 和 Shardbound 等游戏",
    external_wallets_import_cons_heading: "某些导入需要 IMX",
    external_wallets_import_pro_one: "在游戏和市场中玩、使用和制作，或交易物品",
    external_wallets_import_pro_three:
      "在 Immutable X 上免费燃气 - 适用于 Gods Unchained 和 Illuvium 等游戏",
    external_wallets_import_pro_two: "导入整个收藏、多个或单个物品",
    external_wallets_import_pros_heading: "玩、制作、交易",
    external_wallets_no_trading: "无法在游戏中制作或交易外部钱包中的收藏品",
    external_wallets_read_only: "在游戏中，硬币和收藏品将仅为查看",
    external_wallets_use_in_game: "在 Passport 启用的游戏中玩这些收藏品",
    external_wallets: "外部钱包",
    daily_gems: "",
    from_collection: "来自{{collectionName}}",
    games_play_now: "立即游戏",
    games_upcoming_games: "即将推出的游戏",
    games: "游戏",
    im_done: "我完成了",
    immutable_passport: "Immutable Passport",
    immutable_x: "Immutable X",
    immutable_zkevm: "Immutable zkEVM",
    immutable: "Immutable",
    import_items_one: "导入{{count}}个物品",
    import_items_other: "导入{{count}}个物品",
    import_items_to_passport: "导入物品到 Passport",
    import_or_link: "导入或链接？",
    import: "导入",
    import_not_supported: "Importing not available yet for this item type",
    inventory: "库存",
    item_count_one: "{{count}}个物品",
    item_count_other: "{{count}}个物品",
    item_owned_by_linked_wallet_message: "此物品在您关联的{{name}}中",
    language: "中文",
    link_a_wallet_as_view_only_instead: "将钱包链接为只读",
    link_a_wallet: "链接钱包",
    link_wallet_modal_body:
      "将您的游戏物品带入 Passport。只需连接您的 MetaMask（仅限桌面）。 <br/><learnMore>了解更多</learnMore>",
    link_wallet_modal_heading: "导入您的收藏",
    link_wallet_success_title: "Wallet linked",
    linked_wallets: "已链接的钱包",
    loading_moonpay: "正在加载 Moonpay",
    login_active: "正在登录",
    login_check_popup: "看不到登录弹出窗口？",
    login_check_tab: "检查您的下一个选项卡",
    login_confirmed: "您已登录！",
    login_welcome: "欢迎",
    logout: "退出登录",
    logging_out: "Logging Out...",
    marketplace_summary: "在我们的合作市场上查看、购买和出售收藏品。",
    move_coin: "移动 {{symbol}}",
    network: "网络",
    not_found_body: "我们找不到请求的资源",
    not_found_heading: "文件未找到",
    not_found_link: "返回首页",
    number_of_items: "物品数量",
    ok_got_it: "好的，知道了",
    only_in_select_region: "仅在部分地区",
    passport_wallet_address: "您的 Passport 钱包地址",
    passport: "护照",
    ready_for_import: "准备导入",
    remove: "移除",
    reset_preferences: "重置偏好",
    sell: "Sell",
    screen_title_coinbalancelist: "钱包",
    screen_title_collectionlist: "库存",
    screen_title_externalwallets: "外部钱包",
    screen_title_gameslist: "游戏",
    select_all: "全选",
    select_to_import: "选择以导入",
    sell_coin_modal_cta: "前往 TokenTrove",
    sell_coin_modal_desc: "您目前无法在此出售{{symbol}}。",
    sell_coin_modal_point_1: "前往 TokenTrove",
    sell_coin_modal_point_2: "连接您的 Passport",
    sell_coin_modal_point_3: "选择‘使用 MoonPay 购买/出售加密货币’",
    sell_coin_modal_title: "如何在 TokenTrove 中出售{{symbol}}",
    sell_coin: "通过 TokenTrove 出售{{symbol}}",
    show_address: "显示地址",
    showing_items_count: "显示{{count}}个物品",
    showing_items_selection_count: "显示{{selected}}个物品中的{{count}}个物品",
    show_more: "Show more",
    show_more_items: "Show more items",
    sign_in: "登录",
    signing_in_popup: "看不到登录弹出窗口？",
    signing_in_tab: "检查您的下一个选项卡",
    signing_in: "登录中",
    source: "从",
    starkex_balance: "在 Immutable X 上的余额",
    start_collection: "开始您的收藏",
    stateful_empty_body: "目前没有任何内容！",
    stateful_error_body: "请稍后再试",
    stateful_error_heading: "我们遇到了问题 - 我们正在处理",
    statistics: "统计",
    token_history: "代币历史",
    transfer: "Transfer",
    transfer_coin: "Transfer {{symbol}}",
    transfer_caption: "Immutable zkEVM only",
    updated_at: "更新于",
    view_on: "在{{site}}上查看",
    wallet: "钱包",
    weve_hit_a_glitch: "我们遇到了问题",
    swap_coin: "换成另一种硬币",
    sending_assets_to_passport: "将资产发送到 Passport？",
    dont_transfer_on_ethereum_mainnet:
      "不要在以太坊主网（L1）上转账 - 您的资金将被卡住",
    only_transfer_on_immutable_x_or_immutable_zkevm:
      "只能在 Immutable X 或 Immutable zkEVM 上转账。在这里通过 <wallet>钱包</wallet> 或通过 <tokenTrove>TokenTrove</tokenTrove> 进行操作",
    linking_wallet: "链接钱包",
    link_a_wallet_name: "链接{{wallet}}钱包",
    sending_funds_to_passport: "将资金发送到您的 Passport",
    only_add_funds_on_immutable_x_or_immutable_zkevm:
      "只能在 Immutable X 或 Immutable zkEVM 上添加资金。",
    you_can_add_funds_by:
      "您可以在这里通过 <wallet>钱包</wallet>（添加硬币）或通过连接您的钱包在 <tokenTrove>TokenTrove</tokenTrove> 上进行操作",
    passport_l2_wallet_address: "Passport L2 钱包地址",
    sort_newest_first: "最新的在前",
    sort_oldest_first: "最旧的在前",
    link_wallet_already_linked_wallet: "看起来您已经链接了此钱包！",
    link_wallet_something_went_wrong: "糟糕，出了点问题",
    link_wallet_could_not_be_linked: "您的钱包这次无法链接。",
    link_wallet_link_additional_wallets:
      "要链接其他钱包，请先切换到 MetaMask 扩展程序或应用中的另一个帐户。",
    link_wallet_ensure_metamask_is_connected:
      "确保您的 MetaMask 已连接（在您的浏览器扩展程序或移动应用中），然后重试。",
    link_wallet_error_ok_button: "好的，知道了",
    add_coin_menu: "添加硬币",
    add_coin_title: "您想如何添加硬币？",
    add_coin_imx_title: "Let's add funds to Immutable X",
    imx_only: "Immutable X only",
    buy: "买",
    buy_caption: "使用卡，可以使用 Google Pay 或 Apple Pay。",
    bridge: "移动",
    bridge_caption: "我在另一个钱包或网络中拥有的硬币。",
    swap_action: "Swap",
    swap: "交换",
    swap_caption: "带着我余额里的硬币。",
    swap_caption_not_available: "在您所在地区不可用",
    marketing_consent_request:
      "Don't miss out on rewards, exclusive content, promos and more",
    marketing_consent_body:
      "We'll email you about Immutable's products and services. You can unsubscribe at any time.",
    marketing_consent_confirm: "Yes please",
    marketing_consent_reject: "Not now",
    bridge_modal_title: "Are you bridging funds in or out?",
    bridging_in: "Bridging in",
    bridging_out: "Bridging out",
    bridge_in_title: "How would you like to bridge?",
    bridge_in_subtitle: "Choose from one of our providers below",
    ethereum_only: "Ethereum only",
    multiple_networks: "Multiple networks",
    multiple_coins: "Multiple coins",
    wallet_connectors_modal_title: "Which wallet do you want to link?",
    with_layerswap: "With Layerswap",
    with_axelar: "With Axelar",
    terms_of_service: "Terms of Service",
    no_smart_contracts:
      "If you&apos;re using a smart contract wallet (not EOA) it&apos;s not supported.",
    link_wallet_failed: "We failed to link the wallet.",
    link_wallet_failed_heading: "Could not link your wallet",
    link_wallet_failed_unverified:
      "We tried to call the wallet and they rejected or it failed.",
    link_wallet_failed_connect_failed:
      "We tried to call the wallet and it could not connect.",
    link_wallet_failed_network_failed_heading: "Switch to the Ethereum network",
    link_wallet_failed_network_failed:
      "Your wallet is currently connected to another network. Please switch networks to link your wallet.",
    link_wallet_failed_already_linked:
      "If your wallet has multiple accounts, ensure you&apos;re connecting to the right account.",
    link_wallet_failed_already_linked_heading:
      "This wallet has already been linked",
    nickname: "Nickname",
    unlink: "Unlink",
    best_for_cex: "Best for CEX and Immutable X",
    recommended: "Recommended",
    with_squid: "With Squid",
    balances_empty_title_variation_a: "So many games, so little funds",
    balances_empty_title_variation_a_with_selected_game:
      "Fund your {{gameName}} quest",
    balances_empty_caption_variation_a:
      "You never know when the next gaming gold rush will hit. Be ready",
    balances_empty_caption_variation_a_with_selected_game:
      "Fund your adventures and collect rare items from {{gameName}}, Illuvium, and more.",
    balances_empty_title_variation_b: "No funds when the sale comes?",
    balances_empty_caption_variation_b:
      "Don't let empty pockets stand between you and your dream games items",
    learn_more: "Learn more",
    inventory_empty_title_variation_a:
      "A single place for your all game collectables",
    inventory_empty_title_variation_a_with_selected_game:
      "Your {{gameName}} collection starts here",
    inventory_empty_caption_variation_a:
      "Explore true ownership of your favourite game items",
    inventory_empty_caption_variation_a_with_selected_game:
      "Own your gaming world. Explore {{gameName}}, Illuvium, and more in one place.",
    inventory_empty_primary_button_a: "Start collecting",
    inventory_empty_secondary_button_a: "Import or link your collections",
    inventory_empty_title_variation_b: "Nothing here! Let’s change that",
    inventory_empty_caption_variation_b:
      "Explore true ownership of your favourite game items",
    inventory_empty_primary_button_b: "Browse collections",
    inventory_empty_secondary_button_b: "Bring them from another wallet",
    wallets_empty_title_variation_a: "Got collectables? Use them in game",
    wallets_empty_title_variation_a_with_selected_game:
      "Got {{gameName}} collectables in another wallet?",
    wallets_empty_caption_variation_a:
      "Link a wallet (EOA or hardware) to use your collectables in game",
    wallets_empty_caption_variation_a_with_selected_game:
      "Link a wallet (EOA or hardware) to use your collectables in game",
    wallets_empty_primary_button_a: "Select a wallet to get started",
    wallets_empty_title_variation_b:
      "Don’t leave your NFTs locked in another wallet",
    wallets_empty_caption_variation_b:
      "Use your collectables in game, just link a wallet (EOA or hardware) to get started",
    wallets_empty_primary_button_b: "Link a wallet",
    creating_wallet_title: "Creating your wallet...",
    creating_wallet_caption: "Hang tight",
    link_wallet_success_empty_title: "No collectibles found in this wallet",
    link_wallet_success_empty_description:
      "Once you add Immutable X and Immutable zkEVM game collectibles to your wallet, they will show up on your inventory as view-only.",
    link_wallet_success_empty_link_another_cta: "Link another wallet",
  },
};

const japanese = {
  translation: {
    ...english.translation,
    close: "閉じる",
    retry: "再試行",
    retry_after_swtich_network: "I’ve switched, try again",
    ok: "OK",
    inventory_action_buy: "Buy",
    inventory_action_sell: "Sell",
    inventory_action_import: "Import",
    inventory_action_transfer: "Transfer",
    marketplace_buy: "Buy",
    marketplace_sell: "Sell",
    marketplace_buy_sell: "Buy or sell at our marketplaces",
    cex_deposit: "Deposit from a CEX",
    cex_deposit_caption: "Via Layerswap",
    layerswap: "Bridge from other chains",
    layerswap_caption: "Faster bridge via Layerswap",
    add_coin: "{{symbol}}を追加",
    add_to_your_collection: "コレクションに追加",
    add_zkevm_balances: "zkEVM残高を追加",
    application_tagline: "あなたの安全なゲーム用ウォレット＆プロフィール",
    asset_transfer_confirming_in_metamask: "MetaMask で確認中",
    asset_transfer_failure_account_mismatch_description:
      "MetaMask ブラウザ拡張がインポート元のアカウント（...{{walletEnding}}で終わる）に接続されていることを確認してから、もう一度お試しください。",
    set_transfer_failure_account_mismatch_title:
      "ブラウザで正しいアカウントに接続していますか？",
    set_transfer_failure_chain_switch_description:
      "<networkLink>Immutable zkEVM network</networkLink> <linkIcon></linkIcon> がウォレットブラウザ拡張機能に追加され、選択されていることを確認してから、再試行してください。",
    set_transfer_failure_chain_switch_title:
      "Immutable zkEVMネットワークをウォレット拡張機能に追加します",
    set_transfer_failure_description:
      "数分後にもう一度お試しください。<br/><br/>アイテムはまだ元のウォレットにあり、この問題を調査するためにログに記録しました。",
    set_transfer_modal_title: "インポートのレビュー",
    asset_transfer_progress_description:
      "これらのアイテムは数分以内にインベントリに表示されるはずです。 <transactionLink>トランザクション</transactionLink>を表示してください",
    set_transfer_progress_title: "それらは途中です！",
    asset_transfer_success_description:
      "今後は、より多くのコレクションをインポートするか、読み取り専用のままにしておくことができます。 <transactionLink>トランザクション</transactionLink>を表示してください",
    set_transfer_success_title: "それは今あなたのパスポートにあります！",
    asset_transfer_view_transaction:
      "ブロックエクスプローラーでトランザクションを表示",
    set_transfer_wallet_loading: "ウォレットを読み込み中",
    balance: "Balance",
    bring_your_own: "持ち込む",
    browse_marketplaces: "マーケットプレイスを閲覧",
    buy_coin: "{{symbol}} 買う",
    cancel: "キャンセル",
    change_language: "言",
    check_metamask_modal_import_body:
      "MetaMask のポップアップの指示に従って Passport に接続してください。次にインポートするコレクションを選択します。",
    eck_metamask_modal_link_body:
      "MetaMask のポップアップの指示に従ってウォレットを読み取り専用としてリンクしてください。",
    eck_metamask_modal_title: "MetaMask のポップアップを確認",
    choose_more_to_import: "インポートするものを選択",
    choose_your_wallet: "ウォレットを選択",
    collection_count_one: "{{count}}コレクション",
    collection_count_other: "{{count}}コレクション",
    collection_empty_heading: "ここにはまだ何もありません！",
    confirm_import: "{{source.name}} で確認",
    confirm_item_heading: "このアイテムを Passport にインポートしますか？",
    confirm_many: "このコレクションからさらに選択",
    confirm_single_item: "このアイテムのみ",
    copied: "Copied",
    creating_wallet: "ウォレットを作成中、数秒かかる場合があります。",
    description: "説明",
    deselect_all: "すべて選択解除",
    destination: "To",
    details: "詳細",
    error_heading: "何か問題が発生しました {{statusCode}}",
    external_wallets_allows: "閲覧とプレイのみ",
    external_wallets_body:
      "Guild of Guardians、Gods Unchained、MetalCore、Shardbound などのゲームアイテムを持ち込みます。MetaMask（デスクトップのみ）を接続するだけです。 <howItWorks>それはどのように機能しますか</howItWorks>",
    ternal_wallets_external: "外部ウォレット",
    external_wallets_heading:
      "他のウォレットにプレイ可能なコレクションがありますか？",
    ternal_wallets_import_con_one:
      "Immutable zkEVM でガスが必要です（MetaMask からのインポートのみ）- Guild of Guardians、MetalCore、Shardbound などのゲームに適用",
    ternal_wallets_import_cons_heading: "一部のインポートには IMX が必要です",
    ternal_wallets_import_pro_one:
      "ゲームやマーケットでプレイ、使用、クラフト、またはアイテムを取引",
    ternal_wallets_import_pro_three:
      "Immutable X 上でガスフリー - Gods Unchained、Illuvium などのゲームに適用",
    ternal_wallets_import_pro_two:
      "コレクション全体、複数、または単一のアイテムをインポート",
    ternal_wallets_import_pros_heading: "プレイ、クラフト、取引",
    external_wallets_no_trading:
      "ゲーム内で外部ウォレットのコレクションをクラフトまたは取引することはできません",
    external_wallets_read_only:
      "ゲーム内ではコインとコレクションが閲覧専用になります",
    external_wallets_use_in_game:
      "Passport 対応のゲームでこれらのコレクションをプレイします",
    external_wallets: "外部ウォレット",
    daily_gems: "",
    from_collection: "{{collectionName}}から",
    games_play_now: "今すぐプレイ",
    games_upcoming_games: "今後のゲーム",
    games: "ゲーム",
    im_done: "完了しました",
    immutable_passport: "Immutable パスポート",
    immutable_x: "Immutable X",
    immutable_zkevm: "Immutable zkEVM",
    immutable: "Immutable",
    import_items_one: "{{count}}アイテムをインポート",
    import_items_other: "{{count}}アイテムをインポート",
    import_items_to_passport: "アイテムを Passport にインポート",
    import_or_link: "インポートまたはリンク？",
    import: "インポート",
    import_not_supported: "Importing not available yet for this item type",
    inventory: "インベントリ",
    item_count_one: "{{count}}アイテム",
    item_count_other: "{{count}}アイテム",
    item_owned_by_linked_wallet_message:
      "このアイテムはリンクされた{{name}}にあります",
    nguage: "日本語",
    link_a_wallet_as_view_only_instead:
      "ウォレットを代わりに読み取り専用としてリンク",
    nk_a_wallet: "ウォレットをリンク",
    link_wallet_modal_body:
      "ゲームアイテムを Passport に持ち込みます。MetaMask（デスクトップのみ）を接続するだけです。 <br/><learnMore>詳細</learnMore>",
    nk_wallet_modal_heading: "コレクションをインポート",
    link_wallet_success_title: "Wallet linked",
    linked_wallets: "リンクされたウォレット",
    loading_moonpay: "Moonpay を読み込んでいます",
    login_active: "ログイン中",
    login_check_popup: "サインインのポップアップが表示されませんか？",
    login_check_tab: "次のタブを確認する",
    login_confirmed: "ログインでしました!",
    login_welcome: "ようこそ",
    logout: "ログアウト",
    logging_out: "Logging Out...",
    marketplace_summary:
      "当社のパートナーマーケットでコレクションを閲覧、購入、販売します。",
    ve_coin: "動く",
    network: "ネットワーク",
    not_found_body: "お探しのリソースが見つかりませんでした",
    not_found_heading: "ファイルが見つかりません",
    not_found_link: "ホームに戻る",
    number_of_items: "アイテム数",
    ok_got_it: "了解しました",
    only_in_select_region: "一部の地域のみ",
    passport_wallet_address: "Your Passport Wallet Address",
    passport: "パスポート",
    ready_for_import: "インポートの準備ができました",
    remove: "削除",
    reset_preferences: "設定をリセットする",
    sell: "Sell",
    screen_title_coinbalancelist: "ウォレット",
    screen_title_collectionlist: "インベントリ",
    screen_title_externalwallets: "外部ウォレット",
    screen_title_gameslist: "ゲーム",
    select_all: "すべて選択",
    select_to_import: "インポートするものを選択",
    sell_coin_modal_cta: "TokenTrove に移動",
    sell_coin_modal_desc: "ここでは{{symbol}}を販売できません。",
    sell_coin_modal_point_1: "TokenTrove に移動",
    sell_coin_modal_point_2: "Passport を接続",
    sell_coin_modal_point_3: "「MoonPay で暗号通貨を購入/販売」を選択",
    sell_coin_modal_title: "{{symbol}} を TokenTrove で販売する方法",
    sell_coin: "TokenTroveを通じて{{symbol}} を売る",
    show_address: "住所表示",
    showing_items_count: "{{count}}アイテムを表示",
    showing_items_selection_count:
      "{{selected}}アイテム中の{{count}}アイテムを表示",
    ow_more: "Show more",
    show_more_items: "Show more items",
    sign_in: "サインイン",
    signing_in_popup: "サインインのポップアップが表示されませんか？",
    signing_in_tab: "次のタブを確認する",
    signing_in: "サインイン中",
    source: "From",
    starkex_balance: "Immutable X 上の残高",
    start_collection: "コレクションを始める",
    stateful_empty_body: "まだここには何もありません！",
    stateful_error_body: "数分後にもう一度お試しください",
    stateful_error_heading: "問題が発生しました - 解決に取り組んでいます",
    statistics: "統計",
    token_history: "トークン履歴",
    transfer: "Transfer",
    transfer_coin: "Transfer {{symbol}}",
    transfer_caption: "Immutable zkEVM only",
    updated_at: "更新日",
    view_on: "{{site}}で表示",
    wallet: "ウォレット",
    weve_hit_a_glitch: "問題が発生しました",
    swap_action: "Swap",
    swap_coin: "別のコインに交換する",
    sending_assets_to_passport: "アセットを Passport に送信中？",
    dont_transfer_on_ethereum_mainnet:
      "イーサリアムメインネット（L1）で送金しないでください - あなたの資金は取り残されます",
    ly_transfer_on_immutable_x_or_immutable_zkevm:
      "Immutable X または Immutable zkEVM でのみ送金できます。ここで <wallet>ウォレット</wallet> または <tokenTrove>TokenTrove</tokenTrove> を使用してください",
    nking_wallet: "ウォレットをリンク",
    link_a_wallet_name: "{{wallet}}ウォレットをリンク",
    sending_funds_to_passport: "資金を Passport に送信",
    only_add_funds_on_immutable_x_or_immutable_zkevm:
      "Immutable X または Immutable zkEVM でのみ資金を追加できます。",
    u_can_add_funds_by:
      "ここで <wallet>ウォレット</wallet>（硬貨を追加）または <tokenTrove>TokenTrove</tokenTrove> でウォレットを接続することができます",
    ssport_l2_wallet_address: "Passport L2 ウォレットアドレス",
    sort_newest_first: "新しい順",
    sort_oldest_first: "古い順",
    link_wallet_already_linked_wallet:
      "おっと、このウォレットはすでにリンクされているようです！",
    nk_wallet_something_went_wrong: "おっと、何か問題が発生しました",
    link_wallet_could_not_be_linked:
      "今回はウォレットをリンクできませんでした。",
    nk_wallet_link_additional_wallets:
      "追加のウォレットをリンクするには、MetaMask 拡張機能またはアプリで別のアカウントに切り替えてください。",
    nk_wallet_ensure_metamask_is_connected:
      "MetaMask が接続されていることを確認して（ブラウザ拡張機能またはモバイルアプリで）、もう一度お試しください。",
    nk_wallet_error_ok_button: "了解しました",
    add_coin_menu: "コインを追加する",
    add_coin_title: "コインはどのように追加しますか?",
    add_coin_imx_title: "Let's add funds to Immutable X",
    imx_only: "Immutable X only",
    buy: "買う",
    buy_caption: "カードあり、Google Pay、Apple Payもご利用いただけます。",
    bridge: "動く",
    bridge_caption: "別のウォレットまたはネットワークにあるコイン",
    swap: "スワップ",
    swap_caption: "私の残高にあるコインを使って。",
    swap_caption_not_available: "お住まいの地域では利用できません",
    marketing_consent_request:
      "Don't miss out on rewards, exclusive content, promos and more",
    rketing_consent_body:
      "We'll email you about Immutable's products and services. You can unsubscribe at any time.",
    rketing_consent_confirm: "Yes please",
    marketing_consent_reject: "Not now",
    bridge_modal_title: "Are you bridging funds in or out?",
    bridging_in: "Bridging in",
    bridging_out: "Bridging out",
    bridge_in_title: "How would you like to bridge?",
    bridge_in_subtitle: "Choose from one of our providers below",
    ethereum_only: "Ethereum only",
    multiple_networks: "Multiple networks",
    multiple_coins: "Multiple coins",
    wallet_connectors_modal_title: "Which wallet do you want to link?",
    with_layerswap: "With Layerswap",
    with_axelar: "With Axelar",
    terms_of_service: "Terms of Service",
    no_smart_contracts:
      "If you&apos;re using a smart contract wallet (not EOA) it&apos;s not supported.",
    link_wallet_failed: "We failed to link the wallet.",
    link_wallet_failed_heading: "Could not link your wallet",
    link_wallet_failed_unverified:
      "We tried to call the wallet and they rejected or it failed.",
    link_wallet_failed_connect_failed:
      "We tried to call the wallet and it could not connect.",
    link_wallet_failed_network_failed_heading: "Switch to the Ethereum network",
    link_wallet_failed_network_failed:
      "Your wallet is currently connected to another network. Please switch networks to link your wallet.",
    link_wallet_failed_already_linked:
      "If your wallet has multiple accounts, ensure you&apos;re connecting to the right account.",
    link_wallet_failed_already_linked_heading:
      "This wallet has already been linked",
    best_for_cex: "Best for CEX and Immutable X",
    recommended: "Recommended",
    with_squid: "With Squid",
    nickname: "Nickname",
    unlink: "Unlink",
    balances_empty_title_variation_a: "So many games, so little funds",
    balances_empty_title_variation_a_with_selected_game:
      "Fund your {{gameName}} quest",
    balances_empty_caption_variation_a:
      "You never know when the next gaming gold rush will hit. Be ready",
    balances_empty_caption_variation_a_with_selected_game:
      "Fund your adventures and collect rare items from {{gameName}}, Illuvium, and more.",
    balances_empty_title_variation_b: "No funds when the sale comes?",
    balances_empty_caption_variation_b:
      "Don't let empty pockets stand between you and your dream games items",
    learn_more: "Learn more",
    inventory_empty_title_variation_a:
      "A single place for your all game collectables",
    inventory_empty_title_variation_a_with_selected_game:
      "Your {{gameName}} collection starts here",
    inventory_empty_caption_variation_a:
      "Explore true ownership of your favourite game items",
    inventory_empty_caption_variation_a_with_selected_game:
      "Own your gaming world. Explore {{gameName}}, Illuvium, and more in one place.",
    inventory_empty_primary_button_a: "Start collecting",
    inventory_empty_secondary_button_a: "Import or link your collections",
    inventory_empty_title_variation_b: "Nothing here! Let’s change that",
    inventory_empty_caption_variation_b:
      "Explore true ownership of your favourite game items",
    inventory_empty_primary_button_b: "Browse collections",
    inventory_empty_secondary_button_b: "Bring them from another wallet",
    wallets_empty_title_variation_a: "Got collectables? Use them in game",
    wallets_empty_title_variation_a_with_selected_game:
      "Got {{gameName}} collectables in another wallet?",
    wallets_empty_caption_variation_a:
      "Link a wallet (EOA or hardware) to use your collectables in game",
    wallets_empty_caption_variation_a_with_selected_game:
      "Link a wallet (EOA or hardware) to use your collectables in game",
    wallets_empty_primary_button_a: "Select a wallet to get started",
    wallets_empty_title_variation_b:
      "Don’t leave your NFTs locked in another wallet",
    wallets_empty_caption_variation_b:
      "Use your collectables in game, just link a wallet (EOA or hardware) to get started",
    wallets_empty_primary_button_b: "Link a wallet",
    creating_wallet_title: "Creating your wallet...",
    creating_wallet_caption: "Hang tight",
    link_wallet_success_empty_title: "No collectibles found in this wallet",
    link_wallet_success_empty_description:
      "Once you add Immutable X and Immutable zkEVM game collectibles to your wallet, they will show up on your inventory as view-only.",
    link_wallet_success_empty_link_another_cta: "Link another wallet",
  },
};

const korean = {
  translation: {
    ...english.translation,
    close: "닫기",
    retry: "다시 시도",
    retry_after_swtich_network: "I’ve switched, try again",
    ok: "OK",
    inventory_action_buy: "Buy",
    inventory_action_sell: "Sell",
    inventory_action_import: "Import",
    inventory_action_transfer: "Transfer",
    marketplace_buy: "Buy",
    marketplace_sell: "Sell",
    marketplace_buy_sell: "Buy or sell at our marketplaces",
    cex_deposit: "Deposit from a CEX",
    cex_deposit_caption: "Via Layerswap",
    layerswap: "Bridge from other chains",
    layerswap_caption: "Faster bridge via Layerswap",
    add_coin: "{{symbol}} 구매",
    add_to_your_collection: "컬렉션에 추가",
    add_zkevm_balances: "zkEVM 잔액 추가",
    application_tagline: "당신의 안전한 게임용 지갑 및 프로필",
    asset_transfer_confirming_in_metamask: "MetaMask에서 확인 중",
    asset_transfer_failure_account_mismatch_description:
      "MetaMask 브라우저 확장이 가져올 계정(끝에 ...{{walletEnding}})에 연결되어 있는지 확인한 후 다시 시도하세요.",
    set_transfer_failure_account_mismatch_title:
      "브라우저에서 올바른 계정에 연결되어 있나요?",
    set_transfer_failure_chain_switch_description:
      "지갑 브라우저 확장 프로그램에 <networkLink>Immutable zkEVM 네트워크</networkLink> <linkIcon></linkIcon>이 추가되고 선택되었는지 확인한 후 다시 시도하세요.",
    set_transfer_failure_chain_switch_title:
      "지갑 확장 프로그램에 Immutable zkEVM 네트워크를 추가하세요",
    set_transfer_failure_description:
      "몇 분 후 다시 시도하세요.<br/><br/>아이템은 여전히 원래 지갑에 있으며 이 문제를 조사하기 위해 이 문제를 기록했습니다.",
    set_transfer_modal_title: "가져오기 검토",
    asset_transfer_progress_description:
      "이것들은 수 분 내에 인벤토리에 나타날 것입니다. <transactionLink>트랜잭션</transactionLink>을 보세요",
    set_transfer_progress_title: "그들은 길을 가고 있어요!",
    asset_transfer_success_description:
      "이제 더 많은 컬렉션을 가져올 수 있거나 읽기 전용으로 남겨둘 수 있습니다. <transactionLink>트랜잭션</transactionLink>을 보세요",
    set_transfer_success_title: "이제 당신의 패스포트에 있어요!",
    asset_transfer_view_transaction: "블록 탐색기에서 트랜잭션 보기",
    asset_transfer_wallet_loading: "지갑 로드 중",
    balance: "Balance",
    bring_your_own: "당신의 것을 가져오세요",
    browse_marketplaces: "마켓플레이스 둘러보기",
    buy_coin: "{{symbol}} 구매",
    cancel: "취소",
    change_language: "언어 변경",
    check_metamask_modal_import_body:
      "MetaMask 팝업의 지시에 따라 Passport에 연결하세요. 그런 다음 가져올 컬렉션을 선택하세요.",
    eck_metamask_modal_link_body:
      "MetaMask 팝업의 지시에 따라 지갑을 읽기 전용으로 연결하세요.",
    eck_metamask_modal_title: "MetaMask 팝업 확인",
    choose_more_to_import: "가져올 항목을 선택하세요",
    choose_your_wallet: "지갑 선택",
    collection_count_one: "{{count}} 컬렉션",
    collection_count_other: "{{count}} 컬렉션",
    collection_empty_heading: "아직 아무 것도 없습니다!",
    confirm_import: "{{source.name}}에서 확인",
    confirm_item_heading: "이 항목을 패스포트에 가져오시겠습니까?",
    confirm_many: "이 컬렉션에서 더 많이 선택",
    confirm_single_item: "이 아이템만",
    copied: "Copied",
    creating_wallet:
      "지갑을 생성 중이며, 이 작업은 몇 초 정도 걸릴 수 있습니다.",
    scription: "설명",
    deselect_all: "모두 선택 취소",
    destination: "To",
    details: "상세",
    error_heading: "{{statusCode}} 오류가 발생했습니다",
    external_wallets_allows: "보기 및 플레이만 허용",
    external_wallets_body:
      "Guild of Guardians, Gods Unchained, MetalCore, Shardbound 등의 게임 아이템을 가져오세요. MetaMask(데스크톱 전용)를 연결하기만 하면 됩니다. <howItWorks>작동 방식</howItWorks>",
    ternal_wallets_external: "외부 지갑",
    external_wallets_heading: "다른 지갑에 플레이 가능한 컬렉션이 있나요?",
    external_wallets_import_con_one:
      "Immutable zkEVM에서 가스가 필요합니다(MetaMask에서 가져오기 전용) - Guild of Guardians, MetalCore, Shardbound 등의 게임에 적용",
    ternal_wallets_import_cons_heading: "일부 가져오기에는 IMX가 필요합니다",
    ternal_wallets_import_pro_one:
      "게임 및 마켓플레이스에서 아이템을 플레이, 사용 및 제작하거나 거래하세요",
    ternal_wallets_import_pro_three:
      "Immutable X에서 가스 무료 - Gods Unchained 및 Illuvium과 같은 게임에 적용",
    ternal_wallets_import_pro_two:
      "전체 컬렉션, 여러 개 또는 단일 항목을 가져오세요",
    ternal_wallets_import_pros_heading: "플레이, 제작, 거래",
    external_wallets_no_trading:
      "게임에서 외부 지갑의 컬렉션을 제작하거나 거래할 수 없습니다",
    external_wallets_read_only:
      "게임에서 코인 및 컬렉션은 읽기 전용으로만 표시됩니다",
    external_wallets_use_in_game:
      "Passport에서 지원하는 게임에서 이 컬렉션을 플레이하세요",
    external_wallets: "외부 지갑",
    daily_gems: "",
    from_collection: "{{collectionName}}",
    games_play_now: "지금 플레이하기",
    games_upcoming_games: "다가오는 게임",
    games: "게임",
    im_done: "완료",
    immutable_passport: "Immutable 패스포트",
    immutable_x: "Immutable X",
    immutable_zkevm: "Immutable zkEVM",
    immutable: "Immutable",
    import_items_one: "{{count}} 항목 가져오기",
    import_items_other: "{{count}} 항목 가져오기",
    import_items_to_passport: "패스포트에 항목 가져오기",
    import_or_link: "가져오기 또는 연결?",
    import: "가져오기",
    import_not_supported: "이 아이템 타입의 가져오기는 아직 지원되지 않습니다",
    inventory: "인벤토리",
    item_count_one: "{{count}} 항목",
    item_count_other: "{{count}} 항목",
    item_owned_by_linked_wallet_message: "이 항목은 연결된 {{name}}에 있습니다",
    language: "한국어",
    link_a_wallet_as_view_only_instead: "대신 읽기 전용으로 지갑 연결",
    link_a_wallet: "지갑 연결",
    link_wallet_modal_body:
      "게임 아이템을 Passport에 가져오세요. MetaMask(데스크톱 전용)를 연결하기만 하면 됩니다. <br/><learnMore>자세히 알아보기</learnMore>",
    nk_wallet_modal_heading: "컬렉션 가져오기",
    link_wallet_success_title: "Wallet linked",
    linked_wallets: "연결된 지갑",
    loading_moonpay: "Moonpay 로드 중",
    login_active: "로그인 중",
    login_check_popup: "로그인 팝업이 표시되지 않나요?",
    login_check_tab: "다음 탭을 확인하세요",
    login_confirmed: "로그인되었습니다!",
    login_welcome: "환영합니다",
    logout: "로그아웃",
    logging_out: "Logging Out...",
    marketplace_summary: "파트너 마켓에서 컬렉션을 보고, 구매하고, 판매하세요.",
    twork: "네트워크",
    not_found_body: "찾고 계신 페이지 찾을 수 없습니다",
    not_found_heading: "파일을 찾을 수 없음",
    not_found_link: "홈으로 돌아가기",
    number_of_items: "아이템 수",
    ok_got_it: "알겠습니다",
    only_in_select_region: "일부 지역에서만",
    passport_wallet_address: "당신의 패스포트 지갑 주소",
    passport: "패스포트",
    ready_for_import: "가져오기 준비 완료",
    remove: "제거",
    reset_preferences: "설정 초기화",
    sell: "Sell",
    screen_title_coinbalancelist: "지갑",
    screen_title_collectionlist: "인벤토리",
    screen_title_externalwallets: "외부 지갑",
    screen_title_gameslist: "게임",
    select_all: "모두 선택",
    select_to_import: "가져오기 선택",
    sell_coin_modal_cta: "TokenTrove로 이동",
    sell_coin_modal_desc: "이곳에서는 {{symbol}}을 판매할 수 없습니다.",
    sell_coin_modal_point_1: "TokenTrove로 이동",
    sell_coin_modal_point_2: "패스포트 연결",
    sell_coin_modal_point_3: "‘MoonPay로 암호화폐 구매/판매’ 선택",
    sell_coin_modal_title: "{{symbol}}을 TokenTrove에서 판매하는 방법",
    sell_coin: "TokenTrove를 통해 {{symbol}} 판매",
    show_address: "주소 표시",
    showing_items_count: "{{count}} 항목 표시",
    showing_items_selection_count: "{{selected}} 항목 중 {{count}} 항목 표시",
    show_more: "Show more",
    show_more_items: "Show more items",
    sign_in: "로그인",
    signing_in_popup: "로그인 팝업이 표시되지 않나요?",
    signing_in_tab: "다음 탭을 확인하세요",
    signing_in: "로그인 중",
    source: "출처",
    starkex_balance: "Immutable X 잔액",
    start_collection: "컬렉션 시작",
    stateful_empty_body: "아직 여기에는 아무 것도 없습니다!",
    stateful_error_body: "잠시 후 다시 시도해주세요",
    stateful_error_heading: "문제가 발생했습니다 - 잠시 후 다시 시도해주세요",
    statistics: "통계",
    token_history: "토큰 이력",
    transfer: "Transfer",
    transfer_coin: "Transfer {{symbol}}",
    transfer_caption: "Immutable zkEVM only",
    updated_at: "업데이트 날짜",
    view_on: "{{site}}에서 보기",
    wallet: "지갑",
    weve_hit_a_glitch: "문제가 발생했습니다",
    sending_assets_to_passport: "Passport로 자산을 보내시겠습니까?",
    dont_transfer_on_ethereum_mainnet:
      "이더리움 메인넷(L1)에서 이체하지 마세요 - 자금이 걸릴 수 있습니다",
    ly_transfer_on_immutable_x_or_immutable_zkevm:
      "Immutable X 또는 Immutable zkEVM에서만 이체할 수 있습니다. 여기서 <wallet>지갑</wallet> 또는 <tokenTrove>TokenTrove</tokenTrove>를 사용하세요",
    nking_wallet: "지갑 연결",
    link_a_wallet_name: "{{wallet}} 지갑 연결",
    move_coin: "{{symbol}} 옮기기",
    sending_funds_to_passport: "Passport로 자금 보내기",
    only_add_funds_on_immutable_x_or_immutable_zkevm:
      "Immutable X 또는 Immutable zkEVM에서만 자금을 추가할 수 있습니다.",
    u_can_add_funds_by:
      "여기서 <wallet>지갑</wallet> (코인 추가) 또 <tokenTrove>TokenTrove</tokenTrove>를 사용하여 지갑을 연결할 수 있습니다",
    ssport_l2_wallet_address: "Passport L2 지갑 주소",
    sort_newest_first: "최신순",
    sort_oldest_first: "오래된 순",
    swap_action: "Swap",
    swap_coin: "다른 코인으로 교환하기",
    link_wallet_already_linked_wallet: "이미 이 지갑을 연결한 것 같아요!",
    link_wallet_something_went_wrong: "문제가 발생했습니다",
    link_wallet_could_not_be_linked: "이번에는 지갑을 연결할 수 없었습니다.",
    link_wallet_link_additional_wallets:
      "추가 지갑을 연결하려면 MetaMask 확장 프로그램 또는 앱에서 다른 계정으로 전환하세요.",
    nk_wallet_ensure_metamask_is_connected:
      "MetaMask가 연결되어 있는지 확인한 후(브라우저 확장 프로그램 또는 모바일 앱에서) 다시 시도하세요.",
    nk_wallet_error_ok_button: "알겠습니다",
    add_coin_menu: "코인 추가하기",
    add_coin_title: "코인을 어떻게 추가하시겠습니까?",
    add_coin_imx_title: "Let's add funds to Immutable X",
    imx_only: "Immutable X only",
    buy: "구매하기",
    buy_caption: "신용카드, Google Pay, Apple Pay를 사용할 수 있습니다.",
    bridge: "옮기기",
    bridge_caption: "다른 지갑이나 네트워크에 보유하고 있는 코인.",
    swap: "전환하기",
    swap_caption: "잔고에 있는 코인으로",
    swap_caption_not_available: "해당 지역에서는 사용할 수 없습니다.",
    marketing_consent_request:
      "Don't miss out on rewards, exclusive content, promos and more",
    rketing_consent_body:
      "We'll email you about Immutable's products and services. You can unsubscribe at any time.",
    rketing_consent_confirm: "Yes please",
    marketing_consent_reject: "Not now",
    bridge_modal_title: "Are you bridging funds in or out?",
    bridging_in: "Bridging in",
    bridging_out: "Bridging out",
    bridge_in_title: "How would you like to bridge?",
    ethereum_only: "Ethereum only",
    multiple_networks: "Multiple networks",
    multiple_coins: "Multiple coins",
    wallet_connectors_modal_title: "Which wallet do you want to link?",
    with_layerswap: "With Layerswap",
    with_axelar: "With Axelar",
    terms_of_service: "Terms of Service",
    no_smart_contracts:
      "If you&apos;re using a smart contract wallet (not EOA) it&apos;s not supported.",
    link_wallet_failed: "We failed to link the wallet.",
    link_wallet_failed_heading: "Could not link your wallet",
    link_wallet_failed_unverified:
      "We tried to call the wallet and they rejected or it failed.",
    link_wallet_failed_connect_failed:
      "We tried to call the wallet and it could not connect.",
    link_wallet_failed_network_failed_heading: "Switch to the Ethereum network",
    link_wallet_failed_network_failed:
      "Your wallet is currently connected to another network. Please switch networks to link your wallet.",
    link_wallet_failed_already_linked:
      "If your wallet has multiple accounts, ensure you&apos;re connecting to the right account.",
    link_wallet_failed_already_linked_heading:
      "This wallet has already been linked",
    best_for_cex: "Best for CEX and Immutable X",
    recommended: "Recommended",
    with_squid: "With Squid",
    nickname: "Nickname",
    unlink: "Unlink",
    balances_empty_title_variation_a: "So many games, so little funds",
    balances_empty_title_variation_a_with_selected_game:
      "Fund your {{gameName}} quest",
    balances_empty_caption_variation_a:
      "You never know when the next gaming gold rush will hit. Be ready",
    balances_empty_caption_variation_a_with_selected_game:
      "Fund your adventures and collect rare items from {{gameName}}, Illuvium, and more.",
    balances_empty_title_variation_b: "No funds when the sale comes?",
    balances_empty_caption_variation_b:
      "Don't let empty pockets stand between you and your dream games items",
    learn_more: "Learn more",
    inventory_empty_title_variation_a:
      "A single place for your all game collectables",
    inventory_empty_title_variation_a_with_selected_game:
      "Your {{gameName}} collection starts here",
    inventory_empty_caption_variation_a:
      "Explore true ownership of your favourite game items",
    inventory_empty_caption_variation_a_with_selected_game:
      "Own your gaming world. Explore {{gameName}}, Illuvium, and more in one place.",
    inventory_empty_primary_button_a: "Start collecting",
    inventory_empty_secondary_button_a: "Import or link your collections",
    inventory_empty_title_variation_b: "Nothing here! Let’s change that",
    inventory_empty_caption_variation_b:
      "Explore true ownership of your favourite game items",
    inventory_empty_primary_button_b: "Browse collections",
    inventory_empty_secondary_button_b: "Bring them from another wallet",
    wallets_empty_title_variation_a: "Got collectables? Use them in game",
    wallets_empty_title_variation_a_with_selected_game:
      "Got {{gameName}} collectables in another wallet?",
    wallets_empty_caption_variation_a:
      "Link a wallet (EOA or hardware) to use your collectables in game",
    wallets_empty_caption_variation_a_with_selected_game:
      "Link a wallet (EOA or hardware) to use your collectables in game",
    wallets_empty_primary_button_a: "Select a wallet to get started",
    wallets_empty_title_variation_b:
      "Don’t leave your NFTs locked in another wallet",
    wallets_empty_caption_variation_b:
      "Use your collectables in game, just link a wallet (EOA or hardware) to get started",
    wallets_empty_primary_button_b: "Link a wallet",
    creating_wallet_title: "Creating your wallet...",
    creating_wallet_caption: "Hang tight",
    link_wallet_success_empty_title: "No collectibles found in this wallet",
    link_wallet_success_empty_description:
      "Once you add Immutable X and Immutable zkEVM game collectibles to your wallet, they will show up on your inventory as view-only.",
    link_wallet_success_empty_link_another_cta: "Link another wallet",
  },
};

const resources: Record<
  "en" | "ch" | "jp" | "kr",
  { translation: typeof english.translation }
> = { en: english, ch: chinese, jp: japanese, kr: korean };

void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "en",
    resources,
    // lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng optio
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export { resources };
export default i18n;
