import { AppHeaderBar, ButtCon, Button } from "@biom3/react";
import Link from "next/link";
import { type Ref, useState } from "react";

import { CopyWalletModal } from "@/components/coin-balances/CopyWalletModal";
import { HOMEPAGE } from "@/constants";
import { usePassportProvider } from "@/context";
import { useIsSmallScreen } from "@/hooks";
import { toSimpleAddressFormat } from "@/utils/util";

export function Header({
  domRef,
  onMenuClick,
  onTitleClick,
}: {
  domRef: Ref<HTMLDivElement> | undefined;
  onMenuClick: () => void;
  onTitleClick: () => void;
}) {
  const { walletAddress } = usePassportProvider();
  const isSmallScreenMode = useIsSmallScreen();
  const [isCopyWalletModalVisible, setCopyWalletModalVisible] = useState(false);

  const onCopyWalletAddress = () => {
    setCopyWalletModalVisible(true);
    walletAddress && navigator.clipboard.writeText(walletAddress);
  };

  return (
    <AppHeaderBar
      domRef={domRef}
      testId="header"
      size={["small", null, "medium"]}
      contentAlign={isSmallScreenMode ? "center" : "left"}
      sx={{
        background: "base.color.translucent.standard.150",
        zIndex: 9,
        mb: {
          default: "base.spacing.x2",
          medium: "base.spacing.x4",
        },
      }}
    >
      <AppHeaderBar.TitleLogo
        testId="header-logo"
        logo="PassportHorizontalLockupCompact"
        rc={<Link onClick={onTitleClick} href={HOMEPAGE} />}
        sx={{ cursor: "pointer" }}
      />
      {isSmallScreenMode && (
        <AppHeaderBar.LeftButtCon
          testId="header-smallscreen"
          size="medium"
          icon="Menu"
          onClick={onMenuClick}
        />
      )}
      <AppHeaderBar.RightSlot>
        {walletAddress && (
          <>
            {isSmallScreenMode ? (
              <ButtCon icon="Wallet" onClick={onCopyWalletAddress} />
            ) : (
              <Button
                onClick={onCopyWalletAddress}
                size="medium"
                variant="tertiary"
              >
                {toSimpleAddressFormat(walletAddress)}
                <Button.Icon icon="Wallet" />
              </Button>
            )}
            <CopyWalletModal
              isCopyWalletModalVisible={isCopyWalletModalVisible}
              setCopyWalletModalVisible={setCopyWalletModalVisible}
            />
          </>
        )}
      </AppHeaderBar.RightSlot>
    </AppHeaderBar>
  );
}
