import { getActivationVariantName } from "@/components/emptystates/useActivationExperiment";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { createContext, useContext, useEffect, useState } from "react";
import { useAnalytics } from "./AnalyticsProvider";
import { usePassportProvider } from "./PassportProvider";

export interface FeatureFlags {
  activation0Experiment: number | undefined;
}

// If flags is undefined the user hasn't been identified yet
export const FeatureFlagContext = createContext<{
  flags: FeatureFlags | undefined;
}>({ flags: undefined });

const useFeatureFlag = () => {
  const ctx = useContext(FeatureFlagContext);
  if (!ctx) {
    throw new Error("useFeatureFlag must be used within a FeatureFlagProvider");
  }
  return ctx;
};
// biome-ignore lint/suspicious/noExplicitAny: Specifically casting from any to T
const safeCast = <T,>(value: any): T | undefined => {
  if (value !== undefined) {
    return value as T;
  }
  return undefined;
};

const FeatureFlagProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { passportState, userInfo } = usePassportProvider();
  const ldClient = useLDClient();
  const [flags, setFlags] = useState<FeatureFlags | undefined>(undefined);
  const { track } = useAnalytics();

  useEffect(() => {
    const getFlags = async () => {
      const userId = userInfo?.sub;
      if (passportState.authenticated && ldClient && userId && !flags) {
        const ldFlags = await ldClient.identify({
          kind: "user",
          key: userId,
          name: userInfo?.nickname,
          email: userInfo?.email,
        });

        const newFlags: FeatureFlags = {
          activation0Experiment: safeCast(ldFlags.activation0Experiment),
        };
        setFlags(newFlags);

        // Track experiments user is enrolled in IF we have an explicit value (not undefined)
        const experimentsToTrack = [
          {
            id: "activation1_experiment",
            variant: newFlags.activation0Experiment
              ? getActivationVariantName(newFlags.activation0Experiment)
              : undefined,
          },
        ];
        for (const experiment of experimentsToTrack) {
          if (experiment.variant) {
            track({
              screen: "Test",
              userJourney: "Split",
              control: "Experiment",
              controlType: "Group",
              extras: experiment,
            });
          }
        }
      }

      // Reset flags if user logs out
      if (!passportState.authenticated) {
        setFlags(undefined);
      }
    };
    getFlags();
  }, [ldClient, passportState.authenticated, userInfo, flags, track]);
  return (
    <FeatureFlagContext.Provider value={{ flags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export { FeatureFlagProvider, useFeatureFlag };
