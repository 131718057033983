import {
  Box,
  CloudImage,
  Stack,
  useGetSubcomponentChild,
  useTheme,
} from "@biom3/react";
import { type ReactNode, useEffect, useRef, useState } from "react";

import { Header, LayoutContent, SideMenu } from "@/components";
import { appConfig } from "@/constants";
import { useAssetImport } from "@/context/AssetImportProvider";
import { useIsSmallScreen } from "@/hooks";

export function Layout({ children }: { children: ReactNode }) {
  const { base } = useTheme();
  const [menuVisible, setMenuVisible] = useState(false);
  const isSmallScreenMode = useIsSmallScreen();
  const { endImport } = useAssetImport();

  const content = useGetSubcomponentChild(children, LayoutContent);
  const menuRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const isMenuClicked = (event: MouseEvent) =>
    menuRef?.current?.contains(event.target as Node);
  const isHeaderClicked = (event: MouseEvent) =>
    headerRef?.current?.contains(event.target as Node);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const handleClickListener = (event: MouseEvent) => {
      if (!isMenuClicked(event) && !isHeaderClicked(event)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickListener);
    return () => {
      document.removeEventListener("mousedown", handleClickListener);
    };
  }, []);

  return (
    <Box testId="whole-page" sx={{ h: "100%" }}>
      <CloudImage
        imageUrl={`${appConfig.ASSET_BASE_URL}/login_background.webp`}
        responsiveSizes={[
          128, 256, 512, 720, 1024, 1280, 1440, 1600, 1920, 2048,
        ]}
        sx={{
          pos: "absolute",
          top: "0px",
          left: "0px",
          w: "100%",
          h: "100%",
          objectFit: "cover",
          objectPosition: "center",
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "&.AwsResizerImage--defaultImage": {
            opacity: 0,
          },
        }}
        relativeImageSizeInLayout="100vw"
      />
      <Stack
        gap="0px"
        sx={{
          h: "100%",
          maxw: `${base.breakpoint.xxLarge}px`,
          m: "0 auto",
          pos: "relative",
          bg: "base.color.translucent.inverse.800",
          backdropFilter: "blur(28px)",
          boxShadow: {
            default: "none",
            xxLarge: "base.shadow.500",
          },
        }}
        testId="inner-container"
      >
        <Header
          domRef={headerRef}
          onMenuClick={() => {
            setMenuVisible(!menuVisible);
          }}
          onTitleClick={() => {
            setMenuVisible(false);
          }}
        />
        <Box
          sx={{
            d: "flex",
            flexDirection: ["column", "row"],
            position: "auto",
            h: "100%",
            overflowY: "auto",
          }}
          testId="sidebar-and-content"
        >
          {(menuVisible || !isSmallScreenMode) && (
            <SideMenu
              fullWidth={isSmallScreenMode}
              domRef={menuRef}
              onItemClick={() => {
                endImport?.();
                setMenuVisible(false);
              }}
            />
          )}
          <Box
            sx={{
              d: menuVisible && isSmallScreenMode ? "none" : "flex",
              flexGrow: 1,
              flexDirection: ["column", null, null, null, "row"],
              px: {
                default: "base.spacing.x2",
                medium: "base.spacing.x4",
              },
            }}
            testId="content"
          >
            {content && (
              <Box
                rc={<main />}
                sx={{
                  d: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                {content}
              </Box>
            )}
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
