import { Box, Icon } from "@biom3/react";

export const Loader = () => (
  <Box
    testId="loader"
    sx={{
      h: "100%",
      d: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Icon
      testId="loader-icon"
      icon="Loading"
      sx={{ w: "base.icon.size.400" }}
    />
  </Box>
);
