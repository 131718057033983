import { useRouter } from "next/router";
import { type ReactNode, useEffect } from "react";

import { Layout, LayoutContent } from "@/components";
import { Home } from "@/components/home/home";
import {
  type DashboardAnalyticsJourneyType,
  useAnalytics,
  usePassportProvider,
} from "@/context";

export function ProtectedLayout({
  children,
  screen,
  userJourney,
}: {
  children: ReactNode;
  screen: string;
  userJourney: DashboardAnalyticsJourneyType;
}) {
  const { passportState } = usePassportProvider();
  const router = useRouter();
  const { page } = useAnalytics();

  useEffect(() => {
    if (passportState.ready && !passportState.authenticated) {
      router.push({
        pathname: "/",
        query: { unauthenticatedPath: router.asPath },
      });
    }
  }, [passportState, router]);

  useEffect(() => {
    page({ screen, userJourney });
  }, [page, screen, userJourney]);

  return passportState.ready ? (
    <Layout>
      <LayoutContent>{children}</LayoutContent>
    </Layout>
  ) : (
    <Home state={"pending"} />
  );
}
