import { Box, type DeeplyNestedSx } from "@biom3/react";
import type { ReactNode } from "react";
import merge from "ts-deepmerge";

import { Onboarding } from "@/components/onboarding";

export const MainContent = ({
  children,
  contentSx = {},
}: {
  children: ReactNode;
  contentSx?: DeeplyNestedSx;
}) => (
  <Box
    testId="main-content"
    rc={<section />}
    sx={merge(
      {
        d: "flex",
        flexGrow: 1,
        flexDirection: "column",
        background: "base.color.translucent.standard.150",
        borderRadius: "base.borderRadius.x8",
        px: "base.spacing.x6",
        pt: "base.spacing.x4",
        pb: "base.spacing.x10",
        pos: "relative",
      },
      contentSx,
    )}
  >
    <Onboarding />
    {children}
  </Box>
);
