import { z } from "zod";

import { type ChainAddress, EnvironmentNames } from "@/types";

const ethereumAddress = () =>
  z.custom<ChainAddress>(
    (val) => typeof val === "string" && val.startsWith("0x"),
  );

export const schema = z
  .object({
    ASSET_BASE_URL: z.string().url(),
    AUTH0_CLIENT_ID: z.string(),
    AUTH0_LOGOUT_REDIRECT_URI: z.string().url(),
    AUTH0_REDIRECT_URI: z.string().url(),
    BRAZE_API_KEY: z.string(),
    BRAZE_API_ENDPOINT: z.string(),
    ENVIRONMENT: z.nativeEnum(EnvironmentNames),
    IMAGE_RESIZER_URL: z.string().url(),
    IMMUTABLE_BASE_URI: z.string().url(),
    IMX_CONTRACT_ADDRESS: ethereumAddress(),
    LAUNCH_DARKLY_CLIENT_ID: z.string(),
    LAYERSWAP_CLIENT_ID: z.string(),
    MOONPAY_API_KEY: z.string(),
    NEW_RELIC_APPLICATION_ID: z.string(),
    NEW_RELIC_LICENSE_KEY: z.string(),
    SEGMENT_APP_NAME: z.string(),
    SEGMENT_KEY: z.string(),
    TOKEN_ICONS_URL: z.string().url(),
    WALLET_CONNECT_PROJECT_ID: z.string().optional(),
  })
  .refine(
    (data) =>
      data.ENVIRONMENT === EnvironmentNames.DEV ||
      ((data.ENVIRONMENT === EnvironmentNames.PRODUCTION ||
        data.ENVIRONMENT === EnvironmentNames.SANDBOX) &&
        data.WALLET_CONNECT_PROJECT_ID !== undefined),
    {
      message:
        "WALLET_CONNECT_PROJECT_ID must be set in production or sandbox environments",
    },
  );

export const appConfigFn = () =>
  schema.parse({
    ASSET_BASE_URL: process.env.NEXT_PUBLIC_ASSET_BASE_URL,
    AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    AUTH0_LOGOUT_REDIRECT_URI:
      process.env.NEXT_PUBLIC_AUTH0_LOGOUT_REDIRECT_URI,
    AUTH0_REDIRECT_URI: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI,
    BRAZE_API_KEY: process.env.NEXT_PUBLIC_BRAZE_API_KEY,
    BRAZE_API_ENDPOINT: process.env.NEXT_PUBLIC_BRAZE_API_ENDPOINT,
    ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    IMAGE_RESIZER_URL: process.env.NEXT_PUBLIC_IMAGE_RESIZER_URL,
    IMMUTABLE_BASE_URI: process.env.NEXT_PUBLIC_IMMUTABLE_BASE_URI,
    IMX_CONTRACT_ADDRESS: process.env.NEXT_PUBLIC_IMX_CONTRACT_ADDRESS,
    LAUNCH_DARKLY_CLIENT_ID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
    LAYERSWAP_CLIENT_ID: process.env.NEXT_PUBLIC_LAYERSWAP_CLIENT_ID,
    MOONPAY_API_KEY: process.env.NEXT_PUBLIC_MOONPAY_API_KEY,
    NEW_RELIC_APPLICATION_ID: process.env.NEXT_PUBLIC_NEW_RELIC_APPLICATION_ID,
    NEW_RELIC_LICENSE_KEY: process.env.NEXT_PUBLIC_NEW_RELIC_LICENSE_KEY,
    SEGMENT_APP_NAME: process.env.NEXT_PUBLIC_SEGMENT_APP_NAME,
    SEGMENT_KEY: process.env.NEXT_PUBLIC_SEGMENT_KEY,
    TOKEN_ICONS_URL: process.env.NEXT_PUBLIC_TOKEN_ICONS_URL,
    WALLET_CONNECT_PROJECT_ID:
      process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
  });
