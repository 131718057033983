import { AnimatedEmptyState } from "@/components/emptystates/AnimatedEmptyState";
import { CarouselEmptyState } from "@/components/emptystates/CarouselEmptyState";
import { useAnalytics, useFeatureFlag, usePassportProvider } from "@/context";
import { useGetGrants } from "@/hooks/useGetGrants";
import type { Game } from "@/types";
import { t } from "i18next";
import { useMemo } from "react";
import { useEffect, useState } from "react";

export enum ActivationVariants {
  carousel = 1,
  animated = 2,
  control = 3,
}

export const getActivationVariantName = (variant: number) => {
  switch (variant) {
    case ActivationVariants.carousel:
      return "carousel";
    case ActivationVariants.animated:
      return "animated";
    case ActivationVariants.control:
      return "control";
  }
};

export type EmptyStateKeys = "inventory" | "balances" | "wallets";

const getRiveStates = (translationPrefix: EmptyStateKeys) => {
  switch (translationPrefix) {
    case "inventory":
      return { riveStateMachine: "Inventory", riveInputPath: "Smiley - White" };
    case "balances":
      return { riveStateMachine: "Balance", riveInputPath: "Smiley -Black" };
    case "wallets":
      return {
        riveStateMachine: "External Wallets",
        riveInputPath: "Smiley - White",
      };
  }
};

const getUserJourney = (translationPrefix: EmptyStateKeys) => {
  switch (translationPrefix) {
    case "inventory":
      return "Collections";
    case "balances":
      return "Balances";
    case "wallets":
      return "Wallets";
  }
};

const selectRandomGame = (games: Game[]): Game | undefined => {
  if (games.length === 0) return undefined;
  const randomIndex = Math.floor(Math.random() * games.length);
  return games[randomIndex];
};

export const useActivationExperiment = (
  rivePath: string,
  featureKey: EmptyStateKeys,
  primaryButtonClick: (selectedGame: Game | undefined) => void,
  secondaryButtonClick: () => void,
) => {
  const { track } = useAnalytics();
  const { walletAddress } = usePassportProvider();
  const { flags } = useFeatureFlag();
  const activation0Experiment = useMemo(
    () => flags?.activation0Experiment ?? ActivationVariants.control,
    [flags],
  );
  const { riveStateMachine, riveInputPath } = getRiveStates(featureKey);
  const { data: mappedGames } = useGetGrants();
  const [selectedGame, setSelectedGame] = useState<Game | undefined>(undefined);

  useEffect(() => {
    if (mappedGames && mappedGames.length > 0) {
      const selectedGame = selectRandomGame(mappedGames);
      setSelectedGame(selectedGame);
      track({
        screen: "Activation",
        userJourney: "Onboarding",
        control: "GameContext",
        controlType: "Effect",
      });
    }
  }, [mappedGames, track]);

  const dynamicTitleKey_a = selectedGame?.name
    ? `${featureKey}_empty_title_variation_a_with_selected_game`
    : `${featureKey}_empty_title_variation_a`;
  const dynamicCaptionKey_a = selectedGame?.name
    ? `${featureKey}_empty_caption_variation_a_with_selected_game`
    : `${featureKey}_empty_caption_variation_a`;

  switch (activation0Experiment) {
    case ActivationVariants.carousel:
      return {
        component: (
          <CarouselEmptyState
            creatingWallet={!walletAddress}
            titleKey={t(dynamicTitleKey_a, { gameName: selectedGame?.name })}
            captionKey={t(dynamicCaptionKey_a, {
              gameName: selectedGame?.name,
            })}
            primaryButtonKey={`${featureKey}_empty_primary_button_a`}
            secondaryButtonKey={`${featureKey}_empty_secondary_button_a`}
            primaryButtonClick={() => {
              track({
                screen: "EmptyState",
                userJourney: getUserJourney(featureKey),
                control: "Primary",
                controlType: "Button",
                action: "Pressed",
                extras: {
                  showingGameContext: selectedGame !== undefined,
                  gameName: selectedGame?.name,
                },
              });
              primaryButtonClick(selectedGame);
            }}
            secondaryButtonClick={() => {
              track({
                screen: "EmptyState",
                userJourney: getUserJourney(featureKey),
                control: "Secondary",
                controlType: "Button",
                action: "Pressed",
                extras: {
                  showingGameContext: selectedGame !== undefined,
                  gameName: selectedGame?.name,
                },
              });
              secondaryButtonClick();
            }}
            gameData={selectedGame ? selectedGame : undefined}
          />
        ),
        displayControl: false,
      };
    case ActivationVariants.animated:
      return {
        component: (
          <AnimatedEmptyState
            creatingWallet={!walletAddress}
            rivePath={rivePath}
            riveStateMachine={riveStateMachine}
            riveInputPath={riveInputPath}
            titleKey={`${featureKey}_empty_title_variation_b`}
            captionKey={`${featureKey}_empty_caption_variation_b`}
            primaryButtonKey={`${featureKey}_empty_primary_button_b`}
            secondaryButtonKey={`${featureKey}_empty_secondary_button_b`}
            primaryButtonClick={() => {
              track({
                screen: "EmptyState",
                userJourney: getUserJourney(featureKey),
                control: "Primary",
                controlType: "Button",
                action: "Pressed",
              });
              primaryButtonClick(selectedGame);
            }}
            secondaryButtonClick={() => {
              track({
                screen: "EmptyState",
                userJourney: getUserJourney(featureKey),
                control: "Secondary",
                controlType: "Button",
                action: "Pressed",
              });
              secondaryButtonClick();
            }}
          />
        ),
        displayControl: false,
      };
    default:
      return {
        component: <></>,
        displayControl: true,
      };
  }
};
