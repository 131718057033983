import { Box, Logo } from "@biom3/react";
import type { ReactNode } from "react";

export function UnprotectedLayout({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "base.color.translucent.inverse.700",
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        justifyContent: "center",
        textAlign: "center",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "base.color.translucent.emphasis.100",
          borderRadius: "base.borderRadius.x8",
          boxShadow: "base.shadow.500",
          color: "base.color.text.body.primary",
          display: "flex",
          flexDirection: "column",
          gap: "base.spacing.x4",
          padding: "base.spacing.x8",
        }}
      >
        <Logo
          logo="PassportSymbolOutlined"
          sx={{
            width: "base.icon.size.600",
          }}
        />
        {children}
      </Box>
    </Box>
  );
}
